<template>
    <TemplateDetails :data="article" :loading="articleLoading" :getFunction="fetchArticle">
        <PageBack />
        <section class="article-detail">
            <PageHeader :title="metadata?.[0]?.value">
                <template v-slot:text>
                    <Tag v-if="article?.state === 'sold'" :text="$t('global.sold')" color="green2"/>
                    <Tag v-else :text="$t(`articles.${isPublished ? 'published' : 'stocked'}`)" :color="isPublished ? 'green2' : 'orange'" />
                </template>
                <template v-slot:actions>
                    <ButtonLink :border="true" size="m" color="black" icon="eye" :to="{ name: 'recom_resale', params: { id: article?.tradeInCart?.id } }">
                        {{ $t('global.see_tradein') }}
                    </ButtonLink>
                    <Button v-if="isPublished" size="m" color="orange" icon="unpublish" @click.native.prevent="updateStatus">{{ $t('articles.btn_published') }}</Button>
                    <Button v-else-if="article?.state !== 'sold'" size="m" color="green2" icon="publish" @click.native.prevent="updateStatus">{{ $t('articles.btn_not_published') }}</Button>
                </template>
            </PageHeader>

            <GridContainer>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('global.informations')">
                            <FieldSelect :border="true" :options="langs" :selected="selectedLang" @input="handleLangChange" />
                        </GridHeader>
                        <table>
                            <ArticleMetadataEdit
                                    v-for="data in metadata"
                                    :key="data.key"
                                    :field="data.key"
                                    :value="data.value"
                                    :locale="selectedLang"
                                    :brand-id="article?.brand?.id"
                            />
                            <tr v-for="key in ['sku', 'ean', 'reference']" :key="key">
                                <th>{{ $t(`global.${key}`) }}</th>
                                <td><TextSimple>{{ article?.[key] }}</TextSimple></td>
                            </tr>
                        </table>
                    </GridContent>
                </GridCard>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('global.photos')" :text="formDataPhotos.length ? $t('articles.grad_text') : $t('articles.no_photos')" />
                        <ArticlePhotos
                                v-if="formDataPhotos?.length"
                                :photos="formDataPhotos"
                                :deletedPhotos="deletedPhotos"
                                @updatePhotos="handlePhotosUpdate"
                                @restorePhoto="restorePhoto"
                        />
                    </GridContent>
                </GridCard>
                <template v-slot:aside>
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('global.detail')" />
                            <SectionTableInfos :object="article" :fields="detailFields" />
                        </GridContent>
                    </GridCard>
                    <GridCard v-if="article?.state !== 'sold'">
                        <GridContent>
                            <GridHeader :title="$t('articles.publication')" />
                            <Status :valid="isPublished" :padding="false" :border="false" :text="$t('articles.status_publication')" />
                        </GridContent>
                    </GridCard>
                    <GridCard v-if="false">
                        <GridContent>
                            <GridHeader :title="$t('articles.filters')" />
                            <TagList>
                                <Tag v-for="filter in filters" :key="'filter-'+filter.value+'-'+filter.key" :text="filter.value" />
                            </TagList>
                        </GridContent>
                    </GridCard>
                    <GridCard v-if="false">
                        <GridContent>
                            <GridHeader :title="$t('articles.collections')" :text="$t('articles.add_tag_text')" />
                        </GridContent>
                    </GridCard>
                </template>
            </GridContainer>
        </section>
    </TemplateDetails>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TemplateDetails from "@/components/templates/TemplateDetails.vue";
import ArticlePhotos from "@/components/ui/article/ArticlePhotos"
import Button from "@/components/ui/button/Button.vue";
import PageBack from "@/components/ui/page/PageBack.vue";
import PageHeader from "@/components/ui/page/PageHeader.vue";
import Tag from '@/components/ui/tag/Tag';
import ButtonLink from '@/components/ui/button/ButtonLink';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import TagList from '@/components/ui/tag/TagList';
import Status from '@/components/ui/status/Status';
import ArticleMetadataEdit from '@/components/ui/article/ArticleMetadataEdit';
import TextSimple from '@/components/ui/text/TextSimple';

export default {
    name: "ConfigWebsiteCatalogueArticleDetails",
    components: {
        TextSimple,
        ArticleMetadataEdit,
        Status,
        TagList,
        SectionTableInfos,
        FieldSelect,
        GridHeader,
        GridContent,
        GridCard,
        GridContainer,
        ButtonLink,
        TemplateDetails,
        PageBack,
        Button,
        PageHeader,
        Tag,
        ArticlePhotos,
    },
    data() {
        return {
            selectedLang: null,
            metadataOrder: ["title", "description", "color", "color_filters", "color_collection", "size", "size_filters", "size_collection", "pointure", "type", "type_filters", "type_collection", "category", "category_filter", "category_collection", "state", "ean", "reference"],
            formDataPhotos: [],
            photos: [],
            formDataInfos: {},
            detailFields: [
                { key: 'createdAt', type: 'date' },
                { key: 'id', type: 'copy' },
                { key: 'sku', type: 'copy' },
                { key: 'reference', type: 'copy' },
                { key: 'brand', type: 'client' },
                { key: 'priceResale', type: 'formatPrice' },
                { key: 'warehouseSlot', type: 'boolean', translationKey: 'stocked' },
                { key: 'state', type: 'tagType'},
            ],
            displayDeletedPhotos: false,
            disabledPhotosCall: false,
        };
    },
    computed: {
        ...mapState(["article", "articleLoading"]),
        metadata() {
            return this.getSortedMetadata(this.article?.metadata?.[this.selectedLang] || {});
        },
        isPublished() {
            return this.article?.state === "published";
        },
        defaultLang() {
            return this.article?.brand?.defaultLocaleCode;
        },
        langs() {
            return this.getAvailableLanguages(this.article?.metadata || {});
        },
        filters() {
            return this.metadata.filter(x => !['title', 'description', 'sku', 'sku_alt', 'composition', 'image_sku'].includes(x.key));
        },
        deletedPhotos() {
            return this.photos?.filter(x => x.deletedAt);
        },
    },
    methods: {
        ...mapActions(["fetchArticle", "updateArticlePhotos", "updateArticleStatusAlt"]),
        handleLangChange(lang) {
            this.selectedLang = lang;
        },
        getSortedMetadata(metadata) {
            return Object.keys(metadata)
                    .map(key => ({ key, value: metadata[key] }))
                    .sort((a, b) => this.compareMetadataKeys(a.key, b.key));
        },
        compareMetadataKeys(keyA, keyB) {
            const indexA = this.metadataOrder.indexOf(keyA);
            const indexB = this.metadataOrder.indexOf(keyB);
            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
        },
        getAvailableLanguages(metadata) {
            return Object.keys(metadata)
                    .filter(lang => lang !== 'photos')
                    .map(lang => ({
                        id: lang,
                        label: `${this.$options.filters.getFlagEmoji(lang)} ${this.$t(`locales.${lang.split("_")[0]}`)}`,
                    }));
        },
        handlePhotosUpdate(updatedPhotos) {
            this.formDataPhotos = updatedPhotos;
            if (!this.disabledPhotosCall) {
                this.updatePhotos();
            }
        },
        updatePhotos() {
            this.disabledPhotosCall = true;
            const playload = {
                id: this.article.id,
                photos: this.photos.map((photoObj, i) => {
                    const position = this.formDataPhotos.indexOf(photoObj.url);
                    return {
                        id: photoObj.id,
                        position: position >= 0 ? position : i,
                        deleted: position < 0,
                    };
                }),
            };
            this.updateArticlePhotos(playload)
                    .then(data => this.photos = data.photos || [])
                    .catch(err => console.error(err))
                    .finally(() => setTimeout(() => this.disabledPhotosCall = false, 1000));
        },
        restorePhoto(photo) {
            this.formDataPhotos.push(photo.url);
            this.deletedPhotos = this.deletedPhotos.filter(p => p.url !== photo.url);
        },
        updateStatus() {
            console.log('upd');
            this.updateArticleStatusAlt({
                id: this.$route.params.id,
                state: this.article?.state === 'stocked' ? 'published' : 'stocked'
            });
        },
    },
    watch: {
        defaultLang: {
            handler(lang) {
                this.selectedLang = lang;
            }, immediate: true
        },
        'article.photos': {
            handler(photos) {
                this.photos = photos;
            }, deep: true, immediate: true
        },
        photos: {
            handler(photos) {
                this.formDataPhotos = photos?.filter(x => !x.deletedAt).map(photo => photo.url) || [];
            }, deep: true
        },
        metadata: {
            handler(metadata) {
                this.formDataInfos = metadata.reduce((infos, data) => {
                    infos[data.key] = data.key === 'description' ? data.value.replaceAll('<br/>', '\n') : data.value;
                    return infos;
                }, {});
            }, immediate: true, deep: true
        },
    }
};
</script>

<style lang="scss">
.article-detail {
    --field-height: 35px;
    --field-padding: 12px;

    .grid__content {
        tr:not(:first-of-type) {
            & > * {
                padding-top: 15px;
            }
        }

        table {
            width: 100%;
        }

        th {
            padding-right: 30px;
            white-space: nowrap;
            width: 1%;
            vertical-align: middle;
        }
    }

    .field {
        flex-grow: 1;
        margin-top: 0;

        &.has-border .field__input {
            border: var(--border-1);
        }

        &__input {
            border-radius: var(--border-radius-1);

            & > * {
                font-size: 1.3rem;
            }
        }

        textarea {
            min-height: 200px;
        }
    }

    &__photos {

        img {
            width: 100%;
            height: auto;
            position: relative;
            z-index: 1;
        }

        li {
            position: relative;
        }

        & > ul > li {
            cursor: grab;

            &:focus {
                cursor: grabbing;
            }
        }

        ul {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            margin-bottom: 20px;

            button {
                position: absolute;
                top: 5px;
                right: 5px;
                z-index: 2;
            }
        }

        &__deleted {
            margin-top: 20px;
            padding: 15px;
            border-radius: var(--border-radius-1);
            border: var(--border-1);
            overflow: hidden;
            gap: 10px;
        }
    }

    .page-header__actions {
        .button.is-icon {
            border: 0;
        }
    }
}
</style>
