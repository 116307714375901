<template>
    <div>
        <span @click.prevent="open">
            <slot name="button" />
        </span>
        <div class="popin" :class="{'is-visible': isOpen, 'is-center': center, 'is-large':large, 'is-fullscreen':fullScreen}" @click.self="close">
            <div class="popin__inner">
                <button class="popin__close" @click.prevent="close"><SvgIcon name="cross"/></button>
                <div class="popin__heading" v-if="title || subtitle">
                    <TextTitle v-if="title" tag="h2" size="m">{{ title }}</TextTitle>
                    <TextSimple v-if="subtitle">{{ subtitle }}</TextSimple>
                    <TextSimple v-if="text" v-html="text"/>
                </div>
                <slot name="content"/>
                <div class="popin__bottom" v-if="$slots.bottom">
                    <slot name="bottom"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextTitle from '@/components/ui/text/TextTitle';
import TextSimple from '@/components/ui/text/TextSimple';
import SvgIcon from '@/components/ui/svg/SvgIcon';

export default {
    name: 'PopinItem',
    components: {TextSimple, TextTitle, SvgIcon},
    props: {
        title: String,
        subtitle: String,
        text: String,
        center: Boolean,
        large: {
            type: Boolean,
            default: false
        },
        fullScreen: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        close() {
            document.body.classList.remove('no-scroll');
            this.isOpen = false;
            this.$emit('close');
        },
        open() {
            document.body.classList.add('no-scroll');
            this.isOpen = true
        }
    }
}
</script>

<style lang="scss">
body.no-scroll {
    overflow: hidden;
}

.popin {
    position: fixed;
    z-index: 999;
    background-color: rgba(0,0,0,.1);
    backdrop-filter: blur(0.5rem);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--page-margin);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background-color ease-out .3s, backdrop-filter ease-out .3s;

    @media screen and (max-height: 800px) {
        padding: 15px;
    }

    &:not(.is-visible) {
        pointer-events: none;
        background-color: transparent;
        backdrop-filter: blur(0);

        .popin__inner {
            opacity: 0;
        }
    }

    &.is-center {
        text-align: center;

        .popin__inner {
            width: fit-content;
        }

        .form-message {
            width: 100%;
        }

        .button {
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__inner {
        width: 100%;
        max-width: 700px;
        background-color: #fff;
        border-radius: var(--border-radius-2);
        box-shadow: var(--box-shadow-2);
        position: relative;
        padding: 30px;
        transition: opacity ease-out .3s;
        max-height: 100%;
        overflow: hidden;

        @media screen and (max-width: 800px) {
            padding: 15px;
        }

        .button + .button {
            margin-top: 10px;
        }
    }

    &.is-large {
        .popin__inner {
            max-width: 900px;
            overflow-y: scroll;
        }
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
        cursor: pointer;

        svg {
            width: 10px;
            height: auto;
        }
    }

    &__heading {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__bottom {
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;

        @media screen and (max-width: 600px) {
            flex-direction: column;
        }

        .button {
            margin: 0 !important;
        }
    }

    &.is-fullscreen {
        
        .popin__close{
            position: fixed;
            top: 45px;
            right: calc(5% + 40px);
            z-index: 2;
            cursor: pointer;
        }

        .popin__inner{
            min-height: 95vh;
            max-width: 90%;
            width: 90%;
            overflow: scroll;
            border: 20px solid white;
            background: #f7f6f3;
            padding: 10px;
            margin: auto;
        }
    }
}
</style>