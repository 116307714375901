<template>
    <TemplateDetails :get-function="getRecomOrder" :data="order" :loading="orderLoading">

        <PageBack/>

        <PageHeader :title="$t('order.recom_title')">
            <template v-slot:text v-if="order">
                <TextSimple color="grey">{{ order.createdAt | dateHour }}</TextSimple>
                <TagType :value="order.stage"/>
            </template>
        </PageHeader>

        <div v-if="order">

            <GridContainer class="order-cms-detail">
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('order.general')"/>
                        <SectionTableInfos :fields="fieldsGeneral" :object="order"/>
                    </GridContent>
                </GridCard>

                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('global.shipping')"/>
                        <SectionTableInfos :fields="fieldsShipping" :object="order"/>
                    </GridContent>
                </GridCard>

                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('global.paiement')"/>
                        <SectionTableInfos :fields="fieldsPaiement" :object="order"/>
                    </GridContent>
                </GridCard>

                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('order.articles')"/>
                        <RecomOrderCardItem v-for="(item, i) in order.items" :key="i" :item="item.tradeIn"/>
                    </GridContent>
                </GridCard>

                <template v-slot:aside>

                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('global.customer')"/>
                            <SectionTableInfos :fields="fieldsUser" :object="order"/>
                        </GridContent>
                    </GridCard>

                    <GridCard>
                        <GridContent v-if="order?.activities">
                            <GridHeader :title="$t('order.track_order')"/>
                            <GridCalendar>
                                <GridCalendarDate
                                        v-for="(activity, i) in order.activities"
                                        :key="i"
                                        :title="$t(`global.${ activity.event}`)"
                                        :date="activity.updatedAt"
                                />
                            </GridCalendar>
                        </GridContent>
                        <GridContent v-if="order?.shipment?.activities?.length">
                            <GridHeader :title="$t('order.track_shipping')"/>
                            <GridCalendar>
                                <GridCalendarDate
                                        v-for="(activity, i) in order.shipment.activities"
                                        :key="i"
                                        :title="$t(`global.${ activity.event}`)"
                                        :date="activity.updatedAt"
                                />
                            </GridCalendar>
                        </GridContent>
                        <GridContent v-if="order?.orderReturns?.[0]?.activities?.length">
                            <GridHeader :title="$t('order.track_return')"/>
                            <GridCalendar>
                                <GridCalendarDate
                                        v-for="(activity, i) in order.orderReturns[0].activities"
                                        :key="i"
                                        :title="$t(`global.${ activity.event}`)"
                                        :date="activity.updatedAt"
                                />
                            </GridCalendar>
                        </GridContent>
                    </GridCard>

                </template>

            </GridContainer>

        </div>

    </TemplateDetails>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TemplateDetails from '@/components/templates/TemplateDetails';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import GridCalendar from '@/components/ui/grid/GridCalendar';
import TagType from '@/components/ui/tag/TagType';
import RecomOrderCardItem from '@/components/ui/order/RecomOrderCardItem';
import TextSimple from '@/components/ui/text/TextSimple';
import GridCalendarDate from '@/components/ui/grid/GridCalendarDate';

export default {
    name: "OrderPage",
    components: {
        GridCalendarDate,
        RecomOrderCardItem, TextSimple, TagType, GridCalendar, GridHeader, GridContent, GridCard, GridContainer, SectionTableInfos, PageHeader, PageBack, TemplateDetails},
    data() {
        return {
            fieldsGeneral: [
                {key: 'reference', translationKey: 'id'},
                {key: 'createdAt', translationKey: 'orderCreateAt', type: 'date'},
                {key: 'channel', translationKey: 'canal', type: 'tagType'},
                {key: 'itemsNb', translationKey:"countArticles"},
                {key: 'payments', type: 'tagTypePayments', size: 'm'},
                {key: 'state', translationKey:"orderStatus", type: 'tagType'},
                {key: 'assignedAt'},
            ],
            fieldsUser: [
                {key: 'client', type: 'client'},
                {key: 'customer.firstName'},
                {key: 'customer.lastName'},
                {key: 'email'},
                {key: 'source', type: 'tagType'},
                {key: 'customer.countryCode', type: 'country'},
            ],
            fieldsShipping: [
                {key: 'shipment.state', translationKey: "shipmentStatus", type: 'tagType', size: 'm'},
                {key: 'orderReturns', type: 'tagTypeReturns', size: 'm'},
                {key: 'shippingMethod.name', translationKey: 'carrierService'},
                {key: 'shipment.trackingNumber', translationKey: 'trackingId'},
                {key: 'shipment.trackingExternalLink', type: 'url'},
                {key: 'shippingAddress', type: 'address'},
                {key: 'billingAddress', type: 'address'},
                {key: 'entryDate', type: 'dateHour'},
                {key: 'estimDate', type: 'dateHour'},
                {key: 'deliveryDate', type: 'dateHour'},
            ],
            fieldsPaiement: [
                {key: 'itemsTotal', translationKey:"subtotal", type: 'formatPrice', force: true},
                {key: 'shippingTotal', type: 'formatPrice', force: true},
                {key: 'taxTotal', type: 'formatPrice', force: true},
                {key: 'discountTotal', type: 'formatPrice', force: true},
                {key: 'total', translationKey: 'totalttc', type: 'formatPrice', force: true}
            ]
        }
    },
    computed: {
        ...mapState(['order', 'orderLoading', 'clients']),
        shopifyOrderUrl() {
            if (!this.order || !this.order.client) return false;
            return `${this.clients.find(c => c.id === this.order.client.id)?.frontUrl}/admin/new-orders/${this.order.shopifyId}`
        }
    },
    methods: {
        ...mapActions(['getRecomOrder'])
    }
}
</script>

<style lang="scss">
.order-cms-detail {
    .grid-calendar ul {
        padding: 0;
        gap: 15px;
    }

    .grid-calendar-date > span {
        border-color: #fff;
    }
}
</style>