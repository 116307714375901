import CatalogueAPI from "@/api/api-catalogue";
import listRoutes from "@/api/list-routes";
import API from "@/api/api-admin";
import Vue from "vue";
import i18n from "@/i18n";

const state = {
  articles: null,
  articlesLoading: false,

  article: {},
  articleLoading: false,

  customTags: null,
  customTagsLoading: null,

  customTagsFromRecommerceMerch: null,
  customTagsFromRecommerceMerchLoading: false,

  productsEan: null,

  articleFieldListValue: {},
  articleFieldListValueLoading: false,

  editArticlePayload: {},
};
const mutations = {
  // ARTICLES
  SET_ARTICLES(state, data) {
    Vue.set(state, "articles", data);
  },
  SET_ARTICLES_LOADING(state, data) {
    Vue.set(state, "articlesLoading", data);
  },
  SET_ARTICLE(state, data) {
    Vue.set(state, "article", data);
  },
  UPDATE_ARTICLE_STATUS(state, data) {
    Vue.set(state.article, "items", data);
  },
  SET_ARTICLE_LOADING(state, data) {
    Vue.set(state, "articleLoading", data);
  },

  // CUSTOM TAGS
  SET_CUSTOM_TAGS(state, data) {
    state.customTags = data;
  },
  SET_CUSTOM_TAGS_LOADING(state, data) {
    state.customTagsLoading = data;
  },

  SET_CUSTOM_TAGS_FROM_RECOMMERCE_MERCH(state, data) {
    state.customTagsFromRecommerceMerch = data;
  },
  SET_CUSTOM_TAGS_FROM_RECOMMERCE_MERCH_LOADING(state, data) {
    state.customTagsFromRecommerceMerchLoading = data;
  },
  SET_PRODUCTS_EAN(state, data) {
    Vue.set(state, "productsEan", data);
  },
  // EDIT ARTICLE
  SET_ARTICLE_FIELD_LIST_VALUE(state, { lang, field, data }) {
    if (!state.articleFieldListValue[lang]) {
      state.articleFieldListValue[lang] = {};
    }
    if (state.articleFieldListValue[lang][field]) {
      state.articleFieldListValue[lang][field] =
        state.articleFieldListValue[lang][field].concat(data);
    } else {
      state.articleFieldListValue[lang][field] = data;
    }
  },
  RESET_ARTICLE_FIELD_LIST_VALUE(state, data) {
    Vue.set(state, "articleFieldListValue", data);
  },
  SET_ARTICLE_FIELD_LIST_VALUE_LOADING(state, isLoading) {
    Vue.set(state, "articleFieldListValueLoading", isLoading);
  },
  SET_EDIT_ARTICLE_PAYLOAD(state, payload) {
    Vue.set(state, "editArticlePayload", payload);
  },
  SET_EDIT_ARTICLE_PHOTOS_PAYLOAD(state, { lang, data, event }) {
    if (event?.length) Vue.set(state.editArticlePayload[lang], data, event);
    if (!event?.length) Vue.delete(state.editArticlePayload[lang], data);
  },
};
const actions = {
  // ARTICLES
  fetchArticles({ commit }, payload) {
    // Est ce que toutes les values du payload existent?
    const allValuesNotUndefined = Object.values(payload).every(
      (elem) => elem !== undefined
    );

    if (allValuesNotUndefined) {
      // Reformate le payload pour qu'il soit accepté par l'API
      const queryString = Object.keys(payload)
        .filter((key) => {
          return payload[key] !== "";
        })
        .map((key) => {
          return `${key}=${payload[key]}`;
        })
        .join("&");

      commit("SET_ARTICLES_LOADING", true);
      API.get(`${listRoutes.newArticles}?${queryString}`)
        .then((res) => {
          commit("SET_ARTICLES", res.data);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des articles:", error);
        })
        .finally(() => {
          commit("SET_ARTICLES_LOADING", false);
        });
    } else {
      return;
    }
  },
  fetchArticlesSingle({ commit }, payload) {
    commit("SET_ARTICLE_LOADING", true);
    commit("SET_ARTICLE", null);

    const queryString = Object.keys(payload)
      .filter((key) => {
        return payload[key] !== "" && payload[key] !== undefined;
        // && payload[key] !== '' && payload[key] !== undefined && !isNaN(payload[key]);
      })
      .map((key) => {
        return `${key}=${payload[key]}`;
      })
      .join("&");

    if (isNaN(payload.page)) return;

    API.get(`${listRoutes.newArticles}?${queryString}`)
      .then((res) => {
        commit("SET_ARTICLE", res.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des articles:", error);
      })
      .finally(() => {
        commit("SET_ARTICLE_LOADING", false);
      });
  },

  fetchNavIds(_, payload) {
    const queryString = Object.keys(payload)
      .map((key) => `${key}=${payload[key]}`)
      .join("&");

    if (payload.page === "undefined" || payload.limit === "") return;
    return API.get(`${listRoutes.newArticles}?${queryString}`).then((res) => {
      const allIDS = res.data.items.map((elem) => elem.id);
      localStorage.setItem("navFrom", JSON.stringify(payload));
      localStorage.setItem("articlesIDS", JSON.stringify(allIDS));
      return allIDS;
    });
  },

  resetArticles({ commit }) {
    commit("SET_ARTICLE", null);
    commit("SET_ARTICLE_LOADING", true);
  },
  fetchArticle({ commit }, id) {
    commit("SET_ARTICLE_LOADING", true);
    commit("SET_ARTICLE", null);

    API.get(listRoutes.newArticle.replace(":id", id))
      .then((res) => {
        commit("SET_ARTICLE", res.data);
      })
      .catch((error) => {
        console.error("Error fetching articles:", error);
      })
      .finally(() => {
        commit("SET_ARTICLE_LOADING", false);
      });
  },

  async updateArticlePhotos({ commit }, { id, photos }) {
    try {
      const res = await API.put(`${listRoutes.putNewArticle.replace(":id", id)}`, {photos: photos});
      commit("SET_NOTIFICATION_MESSAGE", {
        text: i18n.t("articles.photos_updated"),
        color: "green",
        confirmation: true,
      });
      commit('SET_ARTICLE', res.data);
      return res.data;
    } catch (error) {
      commit("SET_NOTIFICATION_MESSAGE", {
        text: i18n.t("articles.error_while_updating_photos"),
        color: "red",
        confirmation: true,
      });
    }
  },

  // CUSTOM TAGS
  getAllCustomTags({ commit }, id) {
    commit("SET_CUSTOM_TAGS_LOADING", true);
    CatalogueAPI.get(`${listRoutes.allCustomTagsByBrand.replace(":id", id)}`)
      .then((res) => {
        commit("SET_CUSTOM_TAGS", res.data);
      })
      .finally(() => {
        commit("SET_CUSTOM_TAGS_LOADING", false);
      });
  },
  postCustomTag({ dispatch }, payload) {
    const { brandID, ...sendValues } = payload;

    CatalogueAPI.post(
      `${listRoutes.postCustomTags.replace(":brandID", brandID)}`,
      sendValues
    ).then((res) => {
      if (res.status === 200) dispatch("getAllCustomTags", brandID);
    });
  },
  deleteCustomTag({ dispatch }, payload) {
    const { id, tagToDelete } = payload;

    CatalogueAPI.delete(
      `${listRoutes.deleteCustomTags
        .replace(":id", parseInt(id))
        .replace(":customTag", tagToDelete)}`
    ).then((res) => {
      if (res.status === 204) dispatch("getAllCustomTags", id);
    });
  },

  async deleteCustomTagsByNameAndBrand({ dispatch }, payload) {
    const { brandID, customTag } = payload;

    try {
      const response = await CatalogueAPI.get(
        `${listRoutes.allCustomTagsByNameAndBrand
          .replace(":brandID", parseInt(brandID))
          .replace(":customTag", customTag)}`
      );
      const ids = response.data.map((elem) => ({
        id: brandID,
        tagToDelete: elem.id,
      }));

      // Crée un tableau de promesses pour chaque suppression
      const deletePromises = ids.map((id) =>
        dispatch("deleteCustomTag", { id: id.id, tagToDelete: id.tagToDelete })
      );

      // Attend la résolution de toutes les promesses avant de continuer
      await Promise.all(deletePromises);

      // Retourne une valeur ou un message indiquant le succès de l'opération
      return true;
    } catch (error) {
      console.error(
        "Erreur lors de la récupération ou suppression des tags personnalisés :",
        error
      );
    }
  },

  getProductsEansFromRecommerceMerchandising({ commit }, data) {
    commit("SET_CUSTOM_TAGS_FROM_RECOMMERCE_MERCH_LOADING", true);
    const { brandID, ean, state } = data;
    return CatalogueAPI.get(
      `${listRoutes.productsEan
        .replace(":brandID", brandID)
        .replace(":ean", ean)
        .replace(":state", state)}`
    )
      .then((res) => {
        commit("SET_CUSTOM_TAGS_FROM_RECOMMERCE_MERCH", res.data);
      })
      .catch((err) => {
        commit("SET_NOTIFICATION_MESSAGE", {
          text: i18n.t(err.response.data.message),
          color: "red",
          confirmation: true,
        });
      })
      .finally(() => {
        commit("SET_CUSTOM_TAGS_FROM_RECOMMERCE_MERCH_LOADING", false);
      });
  },

  updateArticleStatus({ commit }, payload) {
    const { id, ...rest } = payload;
    commit("SET_ARTICLE_LOADING", true);
    API.put(`${listRoutes.putNewArticle.replace(":id", id)}`, rest)
      .then((res) => {
        commit("UPDATE_ARTICLE_STATUS", [res.data]);
      })
      .finally(() => {
        commit("SET_ARTICLE_LOADING", false);
      });
  },
  updateArticleStatusAlt({ commit }, payload) {
    const { id, ...rest } = payload;
    commit("SET_ARTICLE_LOADING", true);

    API.put(`${listRoutes.putNewArticle.replace(":id", id)}`, rest)
      .then((res) => {
        commit("SET_ARTICLE", res.data);
      })
      .finally(() => {
        commit("SET_ARTICLE_LOADING", false);
      });
  },

  // EDIT ARTICLE

  getArticleFieldsListValue({ commit }, payload) {
    const { locale, brand_id, field } = payload;

    if (field.length && locale.length) {
      return CatalogueAPI.get(
        `${listRoutes.catalogueFieldListValue
          .replace(":lang", locale)
          .replace(":id", brand_id)
          .replace(":field", field)}`
      )
        .then((res) => {
          commit("SET_ARTICLE_FIELD_LIST_VALUE", {
            lang: locale,
            field: field,
            data: res.data,
          });
        })
        .catch((err) => {
          commit("SET_NOTIFICATION_MESSAGE", {
            text: i18n.t(err.response.data.message),
            color: "red",
            confirmation: true,
          });
        });
    }
  },
  async submitFieldList({ commit, dispatch }, payload) {
    const { brandID, lang, fields } = payload;

    commit("RESET_ARTICLE_FIELD_LIST_VALUE", {});
    commit("SET_ARTICLE_FIELD_LIST_VALUE_LOADING", true);

    try {
      await dispatch("catalogueGetAllFields", brandID);

      for (const language of lang) {
        for (const field of fields) {
          await dispatch("getArticleFieldsListValue", {
            locale: language,
            brand_id: brandID,
            field: field,
          });
        }
      }
    } catch (error) {
      commit("SET_NOTIFICATION_MESSAGE", {
        text: i18n.t(error.response.data.message),
        color: "red",
        confirmation: true,
      });
    } finally {
      commit("SET_ARTICLE_FIELD_LIST_VALUE_LOADING", false);
    }
  },

  appendEditArticlePayload({ commit }, payload) {
    commit("SET_EDIT_ARTICLE_PAYLOAD", payload);
  },
  appendPhotosArticlePayload({ commit }, payload) {
    commit("SET_EDIT_ARTICLE_PHOTOS_PAYLOAD", payload);
  },

  checkSubmitedPayload({ commit, dispatch, state }, payload) {
    const stateEditArticlePayload = state.editArticlePayload;

    const languagesWithValues = {};
    let allLanguagesHaveValues = true;

    // Vérifie que chaque langue n'est pas vide
    for (const language in stateEditArticlePayload) {
      if (
        Object.prototype.toString.call(stateEditArticlePayload[language]) ===
          "[object Object]" &&
        Object.keys(stateEditArticlePayload[language]).length > 0
      ) {
        // Si la langue n'est pas vide, l'ajoute à l'objet des langues avec valeurs
        languagesWithValues[language] = stateEditArticlePayload[language];
      } else {
        allLanguagesHaveValues = false;
      }
    }

    // Ajoute une vérification pour s'assurer que languagesWithValues n'est pas un objet vide
    const hasAnyLanguageWithValue = Object.keys(languagesWithValues).length > 0;

    // Vérifie si toutes les langues ont des valeurs et si au moins une langue a des valeurs
    if (allLanguagesHaveValues || hasAnyLanguageWithValue) {
      return dispatch("editArticle", {
        datas: allLanguagesHaveValues
          ? stateEditArticlePayload
          : languagesWithValues,
        identification: payload,
      });
    } else {
      // Si aucune langue n'a de valeurs, gère ce cas
      commit("SET_NOTIFICATION_MESSAGE", {
        text: i18n.t("articles.no_value_in_payload"),
        color: "red",
        confirmation: false,
      });
    }
  },
  editArticle({ commit }, payload) {
    const { datas, identification } = payload;
    const { brandID, ean } = identification;

    return CatalogueAPI.post(
      `${listRoutes.editArticle
        .replace(":brandID", brandID)
        .replace(":ean", ean)}`,
      datas
    )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return true;
        }
      })
      .catch((err) => {
        console.log(err);
        commit("SET_NOTIFICATION_MESSAGE", {
          text: i18n.t("articles.error_while_edit_article_submission"),
          color: "red",
          confirmation: false,
        });
      });
  },
};
const getters = {};

const articles = {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default articles;
