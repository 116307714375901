<template>
    <section class="shipping-create">
        <PageBack/>
        <PageHeader
                :title="$t('shippings.create.title')"
                :text="$t('shippings.create.text')"
        />
        <Form
                :fields="fields"
                :model="model"
                :action="createShippingLabel"
        />
        <Popin ref="popin" :title="$t('shippings.create.popin.title')" @close="handleClosePopin">
            <template v-slot:content>
                <SectionTableInfos :fields="fieldsTable" :object="shipping"/>
            </template>
            <template v-slot:bottom>
                <ButtonLink size="m" color="black" icon="download" :to="shipping?.shippingVoucher">{{ $t('shippings.create.popin.button') }}</ButtonLink>
            </template>
        </Popin>
    </section>
</template>

<script>
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import Form from '@/components/ui/form/Form';
import { mapGetters, mapState } from 'vuex';
import ApiAdmin from '@/api/api-admin';
import Popin from '@/components/ui/popin/Popin';
import ButtonLink from '@/components/ui/button/ButtonLink';
import SectionTableInfos from '@/components/sections/SectionTableInfos';

export default {
    name: "ShippingCreate",
    components: {SectionTableInfos, ButtonLink, Popin, Form, PageHeader, PageBack},
    data() {
        return {
            model: {
                brand: null,
                store: null,
                weight: 0
            },
            shipping: null,
            stores: [],
            fieldsTable: [
                {key: 'brand', type: 'client', size: 'm'},
                {key: 'store.name', size: 'l', translationKey: "store"},
                {key: 'shippingVoucher', type: 'pdf', size : 'm'},
                {key: 'shipment.state', type: 'tagType', size : 'l'},
                {key: 'id', type: 'copy', size: 'xl'},
                {key: 'reference', type: 'copy', size: 'm'},
                {key: 'shipment.trackingNumber', type: 'copy', size : 'l'},
                {key: 'shipment.trackingExternalLink', type: 'url', size : 'xl'},
                {key: 'weight', translationKey: 'shippingKg'},
                {key: 'price', type: 'formatPrice'},
                {key: 'createdAt', type: 'dateHour', size : 's'},
                {key: 'updatedAt', type: 'dateHour', size : 's'},
            ]
        }
    },
    computed: {
        ...mapState(['temporaryUser']),
        ...mapGetters(['userClients']),
        clientsOptions() {
          return this.userClients?.length ? [...this.userClients] : [];
        },
        storesOptions() {
            let stores = this.temporaryUser?.stores || [];
            if (!stores?.length) stores = this.stores?.items || []
            return [...stores].map(x => ({id: x.id, label: x.name || x.label}));
        },
        brandFieldIsDisabled() {
            return this.clientsOptions?.length <= 1
        },
        storeFieldIsDisabled() {
            return this.storesOptions?.length <= 1
        },
        fields() {
            return [
                {name: 'brand', type: 'select', required: true, options: this.clientsOptions, disabled: this.brandFieldIsDisabled},
                {name: 'store', type: 'select', required: true, options: this.storesOptions, disabled: this.storeFieldIsDisabled},
                {name: 'weight', type: 'number', required: true, label: 'shippingKg', max: 10, min: 0.01, step: 0.01},
            ]
        }
    },
    methods: {
        createShippingLabel(formData) {
            return new Promise((resolve, reject) => {
                this.shipping = null;
                ApiAdmin.post(this.$listRoutes.shipmentManifest, {
                    ...formData,
                    weight: Number(formData.weight)
                })
                        .then(res => {
                            if (res.status === 200) {
                                this.shipping = res.data;
                                this.$refs.popin.open();
                                resolve(res);
                            } else {
                                reject(res)
                            }
                        })
                        .catch(err => reject(err))
            })
        },
        handleClosePopin() {
            this.shipping = null;
            this.model.weight = 0
        }
    },
    watch: {
        userClients: {
            handler(clients) {
                this.model.brand = clients?.length ? clients[0].id : null;
            }, immediate: true
        },
        'model.brand': {
            handler(brand) {
                ApiAdmin.get(this.$listRoutes.stores.replace(':id', brand))
                        .then(res => this.stores = res.data || [])
                        .catch(() => this.stores = [])
            }, immediate: true
        },
        storesOptions: {
            handler(stores) {
                this.model.store = stores?.length === 1 ? stores[0].id : null;
            }, immediate: true
        }
    }
}
</script>