import axios from 'axios';
import store from '@/store/index';
import router from '@/router';
import {extractFilenameFromHeader} from '@/utils';

let API = axios.create({
    baseURL: `${process.env.VUE_APP_API}/api/v2/admin/`,
    headers: {
        Accept: "application/json",
        'Content-Type': 'application/json'
    },
});

API.interceptors.request.use((config) => {
    if (config.url.includes('export') || config.url.includes('pdf')) {
        config.responseType = 'blob';
    }
    if (config.url.includes('login')) {
        config.baseURL = `${process.env.VUE_APP_AUTH}`
    }
    if (config.url.includes('translations/import')) {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    if (store.state.user && !config.url.includes('login')) {
        config.headers['Authorization'] = `Bearer ${store.state.temporaryUser ? store.state.temporaryUser.token : store.state.user.access_token}`;
    }
    return config;
});

API.interceptors.response.use(response => {
    response.filename = extractFilenameFromHeader(response);
    return response;
}, async error => {
    const status = error?.response?.status;
    if (status == 403) {
        router.push({name: 'error-403'});
    } else if (status != 422) {
        router.push({name: 'admin-home'});
    }
    return Promise.reject(error?.response?.data?.message || error?.response?.data || error.message);
});

export default API

