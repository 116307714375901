<template>
    <div>
        <TemplateTable name="storeTransfers"
                       :title="$t('client.transfers.title')"
                       :url="$listRoutes.storeTransfers.replace(':storeId', storeId).replace(':id', clientId)"
                       :filters="storeTransfersFilters"
                       :table="storeTransfers"
                       :display-page-back="true"
                       :hide-export="true"
                       :no-cache="true"
                       :fields="fields">

            <template v-slot:actions>
                <ButtonLink :to="{name: 'client-store-transfer-create'}" size="m" color="black" icon="plus">{{ $t('client.stock.create_transfer') }}</ButtonLink>
            </template>

        </TemplateTable>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ButtonLink from '@/components/ui/button/ButtonLink';
import TemplateTable from '@/components/templates/TemplateTable';

export default {
    name: 'StoreTransfers',
    components: {TemplateTable, ButtonLink},
    data() {
        return {
            clientId: this.$route.params.id,
            storeId: this.$route.params.storeId,
            fields: [
                {key: 'id', type: 'eye', size: 'xs', route: 'client-store-transfer', param: 'transferId'},
                {key: 'countArticles', size: 'xs'},
                {key: 'currentEvent.event', label: 'currentEvent', size: 's', type: 'transferStage'},
                {key: 'currentEvent.createdAt', label: 'date', size: 's', type: 'dateHour'},
                {key: 'store.name', size: 'l'},
            ]
        }
    },
    computed: {
        ...mapState(['storeTransfers', 'storeTransfersFilters'])
    }
}
</script>

