<template>
    <div class="markdown-editor" :class="!toolbar ? 'is-nottoolbar' : ''">
        <VueEditor v-if="toolbar" v-model="htmlContent" :editor-toolbar="customToolbar" ref="editor"/>
        <VueEditor v-else v-model="autoNewContent" :editorOptions="editorOptions" ref="editor"/>

        <Button v-if="!autoSubmit" @click.native.prevent="submit" :loading="loading" size="m">{{ $t('global.edit') }}</Button>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Button from '@/components/ui/button/Button';
import _ from 'lodash';

export default {
    components: {Button, VueEditor},
    props: {
        content: {
            type: String,
            required: true
        },
        toolbar: {
            type: Boolean,
            default: true
        },
        autoSubmit: {
            type: Boolean,
            default: false
        },
        keyContent: {
            type: String,
        },
        loading: Boolean
    },
    data() {
        return {
            customToolbar: [
                [{'header': [2, 3, 4, 5, 6, false]}],
                ["bold", "italic", "underline", "link"],
                [{ list: "ordered" }, { list: "bullet" }],
                ['clean']
            ],
            editorOptions:{
                modules: {
                    toolbar: false,
                },
            },
            htmlContent: "",
            autoNewContent: "",
            debouncedSubmit: _.debounce(this.submit, 300)
        }
    },
    methods: {
        submit() {
            let content = this.htmlContent.trim();

            if(this.autoSubmit){
                content = this.autoNewContent.trim();
            }

            content = content.trim();
            content = content.replaceAll('\n', '<br>');
            content = content.replaceAll('<p> </p><p>', '<p> ');
            content = content.replaceAll('</p><p> </p>', ' <p>');
            content = content.replaceAll('<p><br></p><p><strong>', '<br><strong>');
            ['u', 'i', 'em', 'strong', 'a', 'ol', 'ul'].forEach(tag => {
                content = content.replaceAll(`<p><br></p><p><${tag}>`, `<br><${tag}>`)
                content = content.replaceAll(`</p><p><${tag}>`, `<${tag}>`)
                content = content.replaceAll(`<p><${tag}>`, `<${tag}>`)
                content = content.replaceAll(`</${tag}></p><p>`, `</${tag}>`)
                content = content.replaceAll(`</${tag}></p>`, `</${tag}>`)
            });
            content = content.replaceAll(`</p><p><a`, `<a`)
            content = content.replaceAll(`<p><a`, `<a`)
            content = content.replaceAll('<p><br></p>', '<br>');
            content = content.replaceAll('<p><br><br></p>', '<br><br>');
            content = content.replaceAll('<p><br><br><br></p>', '<br><br><br>');
            content = content.replaceAll('</p><br>', '<br>');
            content = content.replaceAll('</p>', '<br>');
            content = content.replaceAll('<p>', '');
            content = content.replaceAll('<!-- -->', '');
            content = content.replaceAll('&amp;', '&');
            if (content.endsWith('<br>')) {
                content = content.substring(0, content.length - 4);
            }
            if(this.autoSubmit){
                this.$emit('submit', {key: this.keyContent, content: content});
            } else {
                this.$emit('submit', content);
            }
        },
        getContent() {
            let content = this.content;
            content.replaceAll('\n', '<br>')
            content.replaceAll('<br><br><br>', '<br><br>')
            content.replaceAll('<br><br>', '<br>')
            content.replaceAll('<br>', '')
            this.htmlContent = content;
            if (this.autoSubmit) {
                this.autoNewContent = content;
            }
        }
    },
    watch: {
        content() {
            this.getContent();
        },
        autoNewContent: {
            handler: 'debouncedSubmit',
            deep: true,
        }
    },
    mounted() {
        this.getContent();
    }
};
</script>

<style lang="scss">
.ve-design {
    padding: 15px;

    iframe {
        position: relative !important;
    }
}

.markdown-editor {

    &,
    .ql-container {
        font-family: "HKNova", sans-serif;
        font-size: 14px;
        line-height: 1.4;
    }

    .ql-toolbar.ql-snow {
        border-top-right-radius: var(--border-radius-2);
        border-top-left-radius: var(--border-radius-2);
        position: sticky;
        top: 68px;
        z-index: 2;
    }

    .ql-container.ql-snow {
        border-bottom-right-radius: var(--border-radius-2);
        border-bottom-left-radius: var(--border-radius-2);
    }

    &.is-nottoolbar {
        .ql-container.ql-snow {
            border-top-right-radius: var(--border-radius-2);
            border-top-left-radius: var(--border-radius-2);
        }
    }

    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
        background-color: #fff;
        border: var(--border-1);
    }

    * {
        margin: 0;
        padding: 0;
    }
    p {
        display: inline;
    }
    ol, ul, a, p {
        font-size: 14px;
    }
    h1 {
        font-size: 42px;
    }
    h2 {
        font-size: 35px;
    }
    h3 {
        font-size: 30px;
    }
    h4 {
        font-size: 25px;
    }
    h5 {
        font-size: 20px;
    }
    h6 {
        font-size: 16px;
    }
    ul li {
        list-style: disc;
        list-style-position: inside;
    }
    ol li {
        list-style: decimal;
        list-style-position: inside;
    }
    ul, ol {
        margin: 10px 0;
    }
    a {
        color: #000;
        text-decoration: underline;
        display: inline;
    }
    table {
        font-size: 13px;
        margin: 10px 0;
    }
    td {
        padding: 10px;
    }
}
</style>





