<template>
   <TemplateGoldenmetrics
           :title="$t('menu.dashboard')"
           :categories="categories"
           :route="$listRoutes.dashboardGoldenmetrics"
   />
</template>

<script>

import TemplateGoldenmetrics from '@/components/templates/TemplateGoldenmetrics';
export default {
    name: 'DashboardGoldenmetrics',
    components: {TemplateGoldenmetrics},
    data() {
        return {
            categories: [
                { name: 'visitNumber', icon: 'users' },
                { name: 'grossSale', format: 'money', icon: 'bag' },
                { name: 'netSale', format: 'money', icon: 'bag-empty' },
                { name: 'avgBasket', format: 'money', icon: 'bag-empty' },
                { name: 'avgConversionRate', format: '%', icon: 'percent-border' },
                { name: 'str', format: '%', icon: 'diamond-border-circles' },
                { name: 'orderNumber', icon: 'box' },
                { name: 'selledArticleNumber', icon: 'tshirt' },
                { name: 'checkedArticle', icon: 'tshirt-check' },
                { name: 'onlineArticlesNumber', icon: 'tshirt-click' },
                //{ name: 'stock', icon: 'tshirt-hanger' }
            ]
        }
    }
}
</script>