<template>
    <component :is="tag || 'td'" class="table-cell" :class="type ? `table-cell--${type}` : null">
        <router-link v-if="type === 'eye'" :to="{ name: route, params: { [param || 'id']: value } }">
            <SvgIcon name="eye" />
        </router-link>
        <span v-else-if="type === 'resale'">
            <TagLink v-if="isAllowed('resale')" :url="{ name: 'resale', params: { id: value } }" :text="value" />
            <span v-else>{{ value }}</span>
        </span>
        <span v-else-if="type === 'wallet'">
            <router-link v-if="isAllowed('wallet')" :to="{ name: 'wallet', params: { id: value } }">{{ value
                }}</router-link>
            <span v-else>{{ value }}</span>
        </span>
        <span v-else-if="type === 'customer'">
            <router-link v-if="isAllowed('customer')" :to="{ name: 'customer', params: { id: value } }">{{ value
                }}</router-link>
            <span v-else>{{ value }}</span>
        </span>
        <span v-else-if="type === 'promoCode' && object">
            <span v-if="object.type === 'percent'">{{ value | percent }}</span>
            <span v-else>{{ value | formatPrice }}</span>
        </span>
        <ProductButtonLightbox v-else-if="type === 'productLightbox'" :product="object" />
        <TagList v-else-if="type === 'tagList' && value && Array.isArray(value)">
            <TagItem v-for="(x, i) in value" :key="i" color="grey" :text="x" />
        </TagList>
        <ProductCheckbox v-else-if="type === 'productCheckbox'" :product="object" :store-key="param" />
        <span v-else-if="type === 'invoicePeriod' && object">{{ object.invoiceStartDate | momentDate }} - {{
        object.invoiceEndDate | momentDate }}</span>
        <span v-else-if="type === '%' || type === 'percent'" :class="param ? param : ''">{{ value | percent }}</span>
        <OrderCardItem v-else-if="type === 'OrderCardItem'" :item="value" />
        <TagCheck v-else-if="type === 'check'" :value="value" />
        <span v-else-if="type === 'period'">{{ value.start | momentDate }} {{ $t('goldenmetrics.periodTo') }} {{
        value.end | momentDate }}</span>
        <Tag v-else-if="type === 'boolean' && !text" :color="value ? 'green' : 'red'"
            :text="$t(`global.${value ? 'yes' : 'no'}`)" />
        <Tag v-else-if="type === 'boolean' && text" :color="value ? 'green' : 'red'"
            :text="$t(`${value ? text[0] : text[1]}`)" />
        <SvgFlag v-else-if="type === 'flag'" :lang="value.code || value" />
        <span v-else-if="type === 'address'">{{ value.addressLine1 }}, {{ value.zipCode }}, {{ value.city }}, {{ value.countryCode }}</span>
        <span v-else-if="type === 'money'">{{ value | money }}</span>
        <span v-else-if="type === 'formatPrice' && null !== value">{{ value | formatPrice }}</span>
        <span v-else-if="type === 'formatPriceNoCurrency'">{{ value | formatPrice(true) }}</span>
        <span v-else-if="type === 'client' && (value || (object && object.clients))">
            <LinkClient v-if="!object || !object.clients" :name="value.name || value" />
            <span v-else>
                <LinkClient v-for="client in object.clients" :key="client.name" :name="client.name || client" />
            </span>
        </span>
        <span v-else-if="type === 'clients'">
            <span v-if="!value || !value.length">-</span>
            <span v-else>
                <LinkClient v-for="(client, i) in value" :key="i" :name="client.name || client" />
            </span>
        </span>
        <span v-else-if="type === 'image'"><img :src="value" :alt="value"></span>
        <span v-else-if="type === 'isGuest'">{{ isGuestValue }}</span>
        <div v-else-if="type === 'switcher'">
            <ToggleSwitcher :model="value" :isDisable="true" @change="handler(!value)"/>
        </div>
        <span v-else-if="!value || value === '' || value === true">-</span>
        <span v-else-if="type === 'editVoucher'">
            <FormInline v-if="object.client && [12, 14, 19].includes(object.client.id) && object.status === 'manual'"
                :model="value" name="code" :success-msg="$t('voucher.edit.success')"
                :route="$listRoutes.voucherEdit.replace(':id', object.id)" />
            <ButtonCopy v-else :text="value" />
        </span>
        <span v-else-if="type === 'realStore' && object">{{ object?.realStore?.name }}</span>
        <TagLink v-else-if="type === 'pdf'" :url="value" :text="$t('global.download')" icon="download" />
        <span v-else-if="type === 'editInvoiceLine'">
            <TagLink v-if="['OneshotInvoice', 'CreditInvoice'].includes(object.invoiceType || object.type)"
                :url="object.route" :text="$t('global.edit')" icon="edit" />
        </span>
        <TagLink v-else-if="type === 'shopifyCustomer' && value && object.shopifyUrl" :url="object.shopifyUrl"
            :text="value" />
        <TagLink v-else-if="type === 'shopifyProduct' && value && object.client" :data-a="object.client.id"
            :data-b="clients[0].id"
            :url="`${clients.find(c => object.client.id ? c.id === object.client.id : c.name === object.client)?.frontUrl}/admin/products/${value}`"
            :text="value" />
        <TagLink v-else-if="type === 'shopifyOrder' && value && object.client"
            :url="`${clients.find(c => object.client.id ? c.id === object.client.id : c.name === object.client)?.frontUrl}/admin/orders/${value}`"
            :text="value" />
        <TagLink v-else-if="type === 'shopifyCustomer' && object.memberAreaUrl" :url="object.memberAreaUrl"
            :text="value" />
        <template v-else-if="type === 'photos'">
            <TagLink v-for="(photoUrl, i) in value" :key="i" :url="photoUrl" :text="$t('global.see_picture')" />
        </template>
        <template v-else-if="type === 'recom_resale_activities' && value && value.length">
            <template v-if="value.some(obj => obj.event === 'validated')">
                <span v-for="(activity, i) in value.filter(obj => obj.event === 'validated')" :key="i">
                    {{ activity.event }}
                </span>
            </template>
            <template v-else>
                <span v-for="(activity, i) in value" :key="i">
                    {{ activity.event }}
                </span>
            </template>
        </template>
        <TagLink v-else-if="type === 'imgUrl'" :url="value" :text="$t('global.see_picture')" />
        <span v-else-if="type === 'admin'">
            <router-link v-if="isAllowed('admin')" :to="{ name: 'admin', params: { id: value } }">{{ value
                }}</router-link>
            <span v-else>{{ value }}</span>
        </span>
        <span v-else-if="type === 'voucher'">
            <router-link v-if="isAllowed('voucher')" :to="{ name: 'voucher', params: { id: value } }">{{ value
                }}</router-link>
            <span v-else>{{ value }}</span>
        </span>
        <UserCard v-else-if="type === 'userCard'" :email="value.email" :firstname="value.firstname"
            :lastname="value.lastname" :image="value.avatar" :to="{ name: 'customer', params: { id: value.id } }" />
        <!--<ButtonCopy v-else-if="type === 'userEmail'" :text="value.email" :cut="true"/>-->
        <span v-else-if="type === 'userEmail' && value">
            <router-link v-if="isAllowed('customer') && object.user && object.user.id"
                :to="{ name: 'customer', params: { id: object.user.id } }">
                <TextSimple size="xxs">{{ value.email || (value.firstname ? `${value.firstname} ${value.lastname}` :
        null) || value }}</TextSimple>
            </router-link>
            <TextSimple v-else size="xxs">{{ value.email || (value.firstname ? `${value.firstname} ${value.lastname}` :
        null) || value }}</TextSimple>
        </span>
        <ButtonCopy v-else-if="type === 'copy'" :text="value" />
        <TagList v-else-if="type === 'roles' && value && Array.isArray(value) && value.length">
            <TagType v-for="(tag, i) in value.filter(v => v !== 'ROLE_USER')" :key="i" :value="tag"
                translation-key="roles" />
        </TagList>
        <TagType v-else-if="type === 'transferStage'" translation-key="client.transfers.stages" :value="value" />
        <TagList v-else-if="type === 'listTagType' && value && Array.isArray(value) && value.length">
            <TagType v-for="(tag, i) in value" :key="i" :value="tag" />
        </TagList>
        <TagType v-else-if="type === 'tagType'" :value="value && value.name ? value.name : value"
            :translation-key="param ? param : 'global'" />
        <TagType v-else-if="type === 'tagTypePayments'"
            :value="value && value.length && value[0].state ? value[0].state : null"
            :translation-key="param ? param : 'global'" />
        <TagType v-else-if="type === 'tagTypeReturns'"
            :value="value && value.length && value[0].shipment && value[0].shipment.state ? value[0].shipment.state : null"
            :translation-key="param ? param : 'global'" />
        <TagType v-else-if="type === 'cmsTradeInState'" :value="value === 'validated' ? 'confirmed' : value"/>
        <span v-else-if="type === 'date'">{{ value | date }}</span>
        <span v-else-if="type === 'dateHour'">{{ value | dateHour }}</span>
        <span v-else-if="type === 'dateHourFromUTC'">{{ value | dateHourFromUTC }}</span>
        <span v-else-if="typeof value === 'object'">{{ value.name || value.label || value.id }}</span>
        <span v-else-if="type === 'email'">{{ value.email }}</span>
        <TagArticleState v-else-if="type === 'articleState'" :value="value" />
        <TagPublicationState v-else-if="type === 'publicationState'" :value="value" />
        <TagVoucherType v-else-if="type === 'voucherType'" :value="value" />
        <span v-else-if="type === 'shortUrl'"><a target="_blank" :href="value">{{ `...${value.slice(-10)}` }}</a></span>
        <span v-else-if="type === 'url'"><a target="_blank" :href="value">{{ value }}</a></span>
        <span v-else-if="type === 'img' && value && isImage(value)"><img :alt="value" :set="src = value" :src="src"
                @error="src = '/img/placeholder/placeholder-tshirt.png'" /></span>
        <TagLink v-else-if="type === 'productUrl'" :url="value"
            :text="object.shopifyId || object.shopify_id || object.id || value" />
        <ImageProduct v-else-if="type === 'productImg'" :image="value" />
        <span v-else-if="type === 'productTitle'" :set="title = value.split('-')[0].trim()">{{ title.slice(0, 20) }}{{
        title.length > 20 ? '...' : '' }}</span>
        <ButtonLink v-else-if="type === 'memberUrl'" :to="value" icon="profile" size="s" color="grey" />
        <span v-else-if="type === 'translation'">{{ $t(`global.${value}`) }}</span>
        <ButtonDownload v-else-if="type === 'download' && route" size="s" color="grey"
            :route="route.replace(':id', value)" :object="object" />
        <span v-else-if="type === 'gender'">{{ genderValue }}</span>
        <div v-else-if="type === 'routerLink'" @click="handler({ key: object.mailKey })">
            <span>{{ value }}</span>
            <SvgIcon name="edit" />
        </div>
        <div v-else-if="type === 'actions' && menuOptions">
            <ButtonCustom 
                ref="action"
                @click.native.stop.prevent="$root.$emit('ctx-menu', { 
                    el: $refs.action.$el, 
                    menuOptions,
                    payload: { key: value }
                })"
                icon="dots" 
                size="s" 
                color="transparent"
                />

        </div>

        <span v-else>{{ value }}</span>
    </component>
</template>

<script>
import { mapState } from 'vuex';
import LinkClient from '@/components/ui/link/LinkClient';
import SvgFlag from '@/components/ui/svg/SvgFlag';
import Tag from '@/components/ui/tag/Tag';
import TagList from '@/components/ui/tag/TagList';
import TagCheck from '@/components/ui/tag/TagCheck';
import TagVoucherType from '@/components/ui/tag/TagVoucherType';
import UserCard from '@/components/ui/user/UserCard';
import TagType from '@/components/ui/tag/TagType';
import SvgIcon from '@/components/ui/svg/SvgIcon';
import TagLink from '@/components/ui/tag/TagLink';
import ButtonCopy from '@/components/ui/button/ButtonCopy';
import TextSimple from '@/components/ui/text/TextSimple';
import ButtonDownload from '@/components/ui/button/ButtonDownloadPdf';
import ButtonLink from '@/components/ui/button/ButtonLink';
import OrderCardItem from '@/components/ui/order/OrderCardItem';
import FormInline from '@/components/ui/form/FormInline';
import ProductCheckbox from '@/components/ui/product/ProductCheckbox';
import TagItem from '@/components/ui/tag/Tag';
import ImageProduct from '@/components/ui/images/ImageProduct';
import ProductButtonLightbox from '@/components/ui/product/ProductButtonLightbox';
import ButtonCustom from '@/components/ui/button/Button';
import TagArticleState from '@/components/ui/tag/TagArticleState.vue';
import ToggleSwitcher from '@/components/ui/switcher/ToggleSwitcher.vue';

export default {
    name: 'TableCell',
    components: { ProductButtonLightbox, ImageProduct, TagItem, ProductCheckbox, FormInline, OrderCardItem, ButtonLink, ButtonDownload, TextSimple, ButtonCopy, TagLink, TagType, SvgIcon, UserCard, TagArticleState, TagVoucherType, TagCheck, TagList, Tag, SvgFlag, LinkClient, ButtonCustom, ToggleSwitcher },
    data() {
        return {
            routes: this.$router?.options?.routes || [],
        }
    },
    props: {
        value: [Boolean, String, Number, Object, Array],
        object: [Object, Array],
        type: String,
        route: String,
        param: String,
        tag: String,
        text: [String, Array],
        size: String,
        actionPopinIsOpen: Boolean,
        menuOptions: Object,
        handler: Function,
    },
    computed: {
        ...mapState(['clients', 'user', 'temporaryUser']),
        client() {
            return this.$store.getters.getClientById(this.$route.params.id)?.name
        },
        genderValue() {
            if (this.value === 'male') {
                return this.$t('global.male');
            } else if (this.value === 'female') {
                return this.$t('global.female');
            } else if (this.value === 'M') {
                return this.$t('global.male');
            } else if (this.value === 'F') {
                return this.$t('global.female');
            } else {
                return this.value;
            }
        },
        profileValue() {
            const types = ['buyer', 'seller', 'visitor', 'test'];
            if (types.includes(this.value)) {
                return this.$t(`global.${this.value}`);
            }
            return '';
        },
        isGuestValue() {
            if (this.value === true) {
                return this.$t('global.invite');
            } else if (this.value === false) {
                return this.$t('global.inscrit');
            }
            return '';
        }
    },
    methods: {
        isAllowed(name) {
            const route = this.routes.find(r => r.name === name);
            if (route) {
                return !!this[this.temporaryUser ? 'temporaryUser' : 'user'].roles.find(r => route.meta.roles.includes(r))
            }
            return true
        },
        isImage(url) {
            return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
        }
    }
}
</script>

<style lang="scss">
.table-cell {
    vertical-align: middle;

    &>span {
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;

        &.grey-background {
            background-color: var(--color-bg-grey);
            padding: 5px;
            border-radius: 20px;
        }
    }

    &--translationKey:last-of-type {
        width: 70%;
    }

    &--eye {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            width: 30px;
            height: 30px;
            transition: box-shadow ease .2s, transform ease .2s;
            background-color: #fff;

            &:hover {
                box-shadow: 0 2px 2px 2px rgba(0, 0, 0, .1);
                transform: translateY(-2px);
            }
        }
    }

    &--routerLink{
        cursor: pointer;
        text-decoration: underline;
        text-align: left;
        justify-content: flex-start !important;

        & > div {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }

    &>*>img,
    &>img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border: var(--border-1);
        object-position: top;
        border-radius: 50%;
    }

    .user-card {
        margin: 0 auto;

        img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }

        p {
            max-width: 160px;
            overflow: hidden;
        }
    }

    &--client,
    &--userEmail {
        a {
            text-decoration: underline;
            cursor: pointer;

            @media screen and (min-width: 1025px) {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .text {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    a {
        max-width: 100%;

        img {
            pointer-events: none;
        }

        @media screen and (min-width: 1025px) {
            &:hover {

                img {
                    border-color: #cecece;
                }
            }
        }
    }

    .button {
        margin: auto;
    }

    .button-copy {
        max-width: 100%;
    }

    .tag {
        max-width: 100%;

        .icon {
            flex-shrink: 0;
        }

        &>span:not(.icon) {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
</style>