import router from '@/router';
import ApiAdmin from '@/api/api-admin';
import ListRoutes from '@/api/list-routes';

import articles from '@/fake-data/articles.json';

let storeArticlesSelectedClientInStore = window.localStorage.getItem('storeArticlesSelectedClient');
if (storeArticlesSelectedClientInStore) storeArticlesSelectedClientInStore = Number(storeArticlesSelectedClientInStore);
let storeArticlesSelectedInStore = window.localStorage.getItem('storeArticlesSelected');
if (storeArticlesSelectedInStore) storeArticlesSelectedInStore = JSON.parse(storeArticlesSelectedInStore);

const state = {
    stores: null,
    store: null,
    storeLoading: false,
    storeStock: null,
    storeTransfers: null,
    storeTransfersFilters: null,
    storeTransfersLoading: true,
    storeTransfer: null,
    storeTransferLoading: false,
    storeArticlesSelected: storeArticlesSelectedInStore || [],
    storeArticlesSelectedClient: storeArticlesSelectedClientInStore,
    storeTransferTimeline: null
}

const mutations = {
    SET_STORE(state, data) {
        state.store = data
    },
    SET_STORE_LOADING(state, value) {
        state.storeLoading = value
    },
    SET_STORE_STOCK(state, value) {
        state.storeStock = value || []
    },
    SET_STORE_TRANSFER(state, value) {
        state.storeTransfer = value;
        state.storeTransferLoading = false;
    },
    SET_STORE_TRANSFERS(state, value) {
        state.storeTransfers = value
    },
    SET_STORE_TRANSFER_LOADING(state, value) {
        state.storeTransferLoading = value;
    },
    SET_STORE_ARTICLES_SELECTED(state, listId = []) {
        state.storeArticlesSelected = [...listId];
        window.localStorage.setItem('storeArticlesSelected', JSON.stringify(listId))
        window.localStorage.setItem('storeArticlesSelectedClient', router.currentRoute.params.id)
    },
    SET_STORE_TRANSFER_TIMELINE(state, value) {
        state.storeTransferTimeline = value
    },
    DELETE_STORE_TRANSFER(state) {
        state.storeTransfer = null;
        if (state.storeTransfers?.items?.length) {
            state.storeTransfers.items = [...state.storeTransfers.items].filter(x => x.id !==  router.currentRoute.params.transferId)
        }
    }
};

const actions = {
    getStore({commit, state}) {
        const clientId = router.currentRoute.params.id;
        const storeId = router.currentRoute.params.storeId;
        if (state.store && state.store.id == storeId) {
            commit('SET_STORE_LOADING', false)
        } else {
            commit('SET_STORE', null);
            commit('SET_STORE_LOADING', true);
            ApiAdmin.get(ListRoutes.store.replace(':storeId', storeId).replace(':id', clientId))
                .then(res => res.data)
                .then(store => commit('SET_STORE', store))
                .finally(() => commit('SET_STORE_LOADING', false))
        }
    },
    editStore({commit}, data) {
        return new Promise((resolve, reject) => {
            const clientId = router.currentRoute.params.id;
            const storeId = router.currentRoute.params.storeId;
            let playload = {...data};
            ["isActive", "isTest", "salesPoint", "resalesPoint"].forEach(name => {
                if (!playload[name]) playload[name] = false;
            });
            playload.schedule = JSON.stringify(playload.schedule);
            playload.bookingSchedule = JSON.stringify(playload.bookingSchedule);
            ApiAdmin.put(ListRoutes.store.replace(':id', clientId).replace(':storeId', storeId), playload)
                .then(store => {
                    commit('SET_STORE', store);
                    resolve();
                })
                .catch((err) => reject(err))
        })
    },
    getStoreStock({commit}) {
        setTimeout(() => {
            commit('SET_STORE_STOCK', articles);
        }, 1000)
    },
    getStoreTransfer({commit, state}, id) {
        if (!state.storeTransfer || this.state.storeTransfer.id !== id) {
            commit('SET_STORE_TRANSFER_LOADING', true);
            ApiAdmin.get(ListRoutes.storeTransfer
                .replace(':storeId', router.currentRoute.params.storeId)
                .replace(':id', router.currentRoute.params.id)
                .replace(':transferId', router.currentRoute.params.transferId)
            )
                .then(res => res.data)
                .then(data => commit('SET_STORE_TRANSFER', data))
                .catch(() => commit('SET_STORE_TRANSFER', null))
        }
    },
    deleteStoreTransfer({commit}) {
        return new Promise((resolve, reject) => {
            ApiAdmin.delete(
                ListRoutes.storeTransfer
                    .replace(':storeId', router.currentRoute.params.storeId)
                    .replace(':id', router.currentRoute.params.id)
                    .replace(':transferId', router.currentRoute.params.transferId)
            )
                .then(() => {
                    commit('DELETE_STORE_TRANSFER')
                    resolve()
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    createStoreTransfer({commit}, listId) {
        return new Promise((resolve, reject) => {
            ApiAdmin.post(ListRoutes.storeTransfers
                    .replace(':storeId', router.currentRoute.params.storeId)
                    .replace(':id', router.currentRoute.params.id)
                , {articles: [...listId]})
                .then(res => res.data)
                .then(data => {
                    commit('SET_STORE_TRANSFER', data);
                    commit('SET_STORE_ARTICLES_SELECTED', []);
                    resolve(data)
                })
                .catch(err => {
                    commit('SET_STORE_TRANSFER', null);
                    reject(err)
                })
        })
    },
    setStoreArticlesSelected({commit}, articlesId) {
        commit('SET_STORE_ARTICLES_SELECTED', articlesId)
    },
    getStoreTransferTimeline({commit}) {
        ApiAdmin.get(ListRoutes.transfersTimeline)
            .then(res => res.data)
            .then(data => commit('SET_STORE_TRANSFER_TIMELINE', data))
            .catch(err => {
                console.log('Impossible de récupérer la timeline', err);
                commit('SET_STORE_TRANSFER_TIMELINE', [
                    "created",
                    "validated",
                    "to_prepare",
                    "prepared",
                    "to_ship",
                    "shipped",
                    "received",
                    "to_control",
                    "controled",
                    "to_return",
                    "returned",
                    "returning",
                    "returned"
                ])
            })
    }
};

const getters = {};

const stores = {state, mutations, actions, getters};

export default stores