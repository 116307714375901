import API from '@/api/api-admin';
import datasMonitoringAPI from '@/api/api-datamonitoring';
import testsMonitoringAPI from '@/api/api-testmonitoring';

const apiMapping = {
    'monitoringDatas': datasMonitoringAPI,
    'monitoringTests': testsMonitoringAPI,
};

const state = {
    resalesPhotos: null,
    resalesPhotosFilters: null,
    resalesNotPublished: null,
    resalesNotPublishedFilters: null,
    resales: null,
    resalesFilters: null,
    resalesNotReview: null,
    resalesNotReviewFilters: null,
    resalesNotShooting: null,
    resalesNotShootingFilters: null,
    vouchers: null,
    vouchersFilters: null,
    wallets: null,
    walletsFilters: null,
    customers: null,
    customersFilters: null,
    tableRequests: {},
    returns: null,
    returnsFilters: null,
    returnsTreated: null,
    returnsTreatedFilters: null,
    ordersReturns: null,
    ordersReturnsFilters: null,
    ordersReturnsOrder: null,
    ordersReturnsOrderFilters: null,
    bills: null,
    billsFilters: null,
    orders: null,
    ordersFilters: null,
    stock: null,
    stockFilters: null,
    translations: null,
    translationsFilters: null,
    admins: null,
    adminsFilters: null,
    soldArticles: null,
    soldArticlesFilters: null,
    exportLoading: false,
    exportIndex: 0,
    warehouseStocks: null,
    warehouseStocksFilters: null,
    resalesStocks: null,
    resalesStocksFilters: null,
    warehouseStocksRefused: null,
    warehouseStocksRefusedFilters: null,
    promotionsCredits: null,
    promotionsCreditsFilters: null,
    ordersReturned: null,
    ordersReturnedFilters: null,
    promotionsDebits: null,
    promotionsDebitsFilters: null,
    resalesCredited: null,
    resalesCreditedFilters: null,
    monitoringDatas: null,
    monitoringDatasFilters: null,
    monitoringTests: null,
    monitoringTestsFilters: null,
    resalesShipping: null,
    resalesShippingFilters: null,
    ordersReturnsTreated: null,
    ordersReturnsTreatedFilters: null,
    resalesBookings: null,
    resalesBookingsFilters: null,
    invoicesFolders: null,
    invoicesFoldersFilters: null,
    clientAvailableProducts: null,
    clientAvailableProductsFilters: null,
    storeTransferArticles: null,
    storeTransferArticlesFilters: null,
    retailTransferArticles: null,
    retailTransferArticlesFilters: null,
    retailAvailableProducts: null,
    retailAvailableProductsFilters: null,
    articles:null,
    articlesFilters:null,
    ordersRefunded: null,
    ordersRefundedFilters: null,
    recomVouchers: null,
    recomVouchersFilters: null,
    recomSubscribers: null,
    recomSubscribersFilters: null,
    recomSoldArticles: null,
    recomSoldArticlesFilters: null,
    recomTradeinArticles: null,
    recomTradeinArticlesFilters: null,
    recomPromocodes: null,
    recomPromocodesFilters: null,
    warehouseArticles: null,
    warehouseArticlesFilters: null,
    shippings: null,
    shippingsFilters: null
}

const mutations = {
    SET_TABLE(state, data) {
        state[`${data.name}`] = {...data.value};
    },
    SET_TABLE_FILTERS(state, data) {
        let filters = data.value;
        if (filters) {
            // Fix pour faire en sortes que options soit toujours un tableau d'objet
            filters.forEach(filter => {
                if (filter?.options?.length && typeof filter.options[0] !== 'object') {
                    filter.options = filter.options.map(x => ({id: x, label: x}))
                }
            })
        }
        state[`${data.name}Filters`] = filters
    },
    RESET_TABLE(state, name) {
        state[name] = null;
    },
    SET_EXPORT_LOADING(state, value) {
        state.exportLoading = value
    },
    SET_EXPORT_INDEX(state) {
        state.exportIndex += 1;
    }
}

const actions = {
    getTable({commit}, data) {
        const url = `${data.url}?${data.filters}`;
        const currentApi = apiMapping[data.name] || API;
        currentApi.get(url)
            .then(res => {
                if (res && res.data) {
                    if (!res.data.items) res.data = {
                        items: res.data
                    }
                    commit('SET_TABLE', {
                        name: data.name,
                        value: res.data
                    })
                }
            })
            .catch(err => {
                commit('SET_TABLE', {
                    name: data.name,
                    value: []
                });
                console.error('Impossible de récupérer la table', err)
            })
    },
    exportTable({commit, state}, data) {
        commit('SET_EXPORT_INDEX');
        commit('SET_EXPORT_LOADING', true);
        const index = state.exportIndex;
        let filters = data.filters ? data.filters : '';
        if (filters && filters !== '') filters = `?${filters}`;
        const currentApi = apiMapping[data.name] || API;
        currentApi.get(`${data.url}/export${filters}`)
            .then(res => res.data)
            .then(data => {
                const href = URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'export.csv'); // Change with intelligent name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch(() => console.error("Export non disponible. Demandez à un développeur back d'activer cette route : ", `${data.url}/export`))
            .finally(() => commit('SET_EXPORT_LOADING', false));
        setTimeout(() => {
            if (index === state.exportIndex) commit('SET_EXPORT_LOADING', false)
        }, 20000)
    },
    getTableFilters({commit}, data) {
        const currentApi = apiMapping[data.name] || API;
        currentApi.get(`${data.url}/filters`)
            .then(res => {
                if (res && res.data) {
                    commit('SET_TABLE_FILTERS', {
                        name: data.name,
                        value: res.data
                    })
                }
            })
            .catch(() => console.error("Filtres non disponibles. Demandez à un développeur back d'activer cette route : ", `${data.url}/filters`))
    },
    resetTable({commit}, name) {
        commit('RESET_TABLE', name)
    }
}

const getters = {
    recomSoldArticlesWithTVA: (state) => {
        if (state.recomSoldArticles && state.recomSoldArticles.items) {
            return state.recomSoldArticles.items.map(article => {
                article.priceTTC = article.price * (1 + article.tva / 100);
                return article;
            })
        }
        return null;
    }
}

const tables =  {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default tables