<template>
    <TemplateDetails :get-function="getRecomCustomer" :data="customer" :loading="customerLoading">

        <PageBack />

        <PageHeader :title="$t('customer.recom_title')" v-if="customer">
            <template v-slot:actions>
                <ButtonLink v-if="customer.walletId" :to="{ name: 'wallet', params: { id: customer.walletId } }"
                    color="grey" icon="wallet" size="m">{{ $t('customer.btn_wallet') }}</ButtonLink>
                <ButtonLink v-if="customer.memberAreaUrl" :to="customer.memberAreaUrl" color="blue2" icon="profile"
                    size="m" target="_blank">{{ $t('customer.btn_profile') }}</ButtonLink>
            </template>
        </PageHeader>

        <GridContainer>
            <GridCard>
                <GridContent>
                    <SectionTableInfos :object="customer" :fields="fields" />
                </GridContent>
            </GridCard>

            <template v-slot:aside>
                <GridCard v-if="customer && customer.addresses.length">
                    <GridContent v-for="(address, i) in  customer.addresses " :key="i">
                        <SectionTableInfos :object="address" :fields="fieldsAddress"
                            :title="$t('global.address') + ' ' + (i + 1)" />
                    </GridContent>
                </GridCard>
                <GridCard v-if="customer && customer.nbOrders">
                    <GridContent>
                        <SectionTableInfos :object="customer" :fields="fieldsOrders" :title="$t('global.orders')" />
                    </GridContent>
                </GridCard>

                <GridCard v-if="customer && customer.nbReprises">
                    <GridContent>
                        <SectionTableInfos :object="customer" :fields="fieldsResales" :title="$t('global.resales')" />
                    </GridContent>
                </GridCard>
            </template>

            <template v-slot:bottom>
                <div v-if="orders?.length">
                    <GridHeader :title="$t('menu.orders')" :count="orders.length">
                        <ButtonLink size="m" color="black" icon="eye"
                            :to="{ name: 'recom_orders', query: { 'user:eq': customer.email } }">{{
        $t('customer.btn_orders') }}</ButtonLink>
                    </GridHeader>
                    <TableSticky :fields="ordersFields" :data="orders" />
                </div>

                <div v-if="resales?.length">
                    <GridHeader :title="$t('menu.resales')" :count="resales.length">
                        <ButtonLink size="m" color="black" icon="eye"
                            :to="{ name: 'recom_resales-all', query: { 'user:eq': customer.email, page: 1, limit: 25 } }">{{
        $t('customer.btn_resales') }}</ButtonLink>
                    </GridHeader>
                    <TableSticky :fields="resalesFields" :data="resales" />
                </div>

                <div v-if="credits?.length">
                    <GridHeader :title="$t('menu.cmsCredits')" :count="credits.length">
                        <ButtonLink size="m" color="black" icon="eye"
                            :to="{ name: 'recom-vouchers', query: { 'user:eq': customer.email } }">{{
        $t('customer.btn_credits') }}</ButtonLink>
                    </GridHeader>
                    <TableSticky :fields="creditsFields" :data="credits" />
                </div>
            </template>
        </GridContainer>
    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import { mapActions, mapState } from 'vuex';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import ButtonLink from '@/components/ui/button/ButtonLink';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import API from '@/api/api-admin';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import GridHeader from '@/components/ui/grid/GridHeader';

export default {
    name: 'CustomerPage',
    components: {
        GridHeader,
        TableSticky,
        GridContent, GridCard, GridContainer, ButtonLink, SectionTableInfos, PageHeader, PageBack, TemplateDetails
    },
    data() {
        return {
            fields: [
                { key: 'id' },
                { key: 'shopifyId', type: 'shopifyCustomer' },
                { key: 'firstName' },
                { key: 'lastName' },
                { key: 'email', type: 'copy' },
                { key: 'profile' },
                { key: 'isGuest', translationKey: 'state', type: 'isGuest', force: true },
                { key: 'gender', type: 'gender' },
                { key: 'brand', type: 'client' },
                { key: 'emailSubscription', translationKey: 'optinEmail', type: 'boolean' },
                { key: 'smsSubscription', translationKey: 'optinSMS', type: 'boolean' },
                { key: 'nbOrders', force: true },
                { key: 'nbTradeInCarts', force: true, translationKey: 'nbReprises' },
                { key: 'createdAt', type: 'date' },
                { key: 'updatedAt', type: 'date' },
                { key: 'locale', type: 'flag' },
                { key: 'countryCode', size: 'xs' },
                { key: 'memberAreaUrl', type: 'memberUrl' },
            ],
            fieldsAddress: [
                { key: 'firstName' },
                { key: 'lastName' },
                { key: 'addressLine1', translationKey: 'address1' },
                { key: 'addressLine2', translationKey: 'address2' },
                { key: 'zipCode', translationKey: 'zip1' },
                { key: 'city' },
                { key: 'countryCode' }
            ],
            fieldsOrders: [
                { key: 'nbOrders' },
                { key: 'lastOrderDate', type: 'date' },
            ],
            fieldsResales: [
                { key: 'nbReprises' },
                { key: 'lastReturnDate', type: 'date' },
            ],
            orders: null,
            resales: null,
            credits: null,
            ordersFields: [
                { key: 'id', type: 'eye', route: 'recom_order', size: 'xs' },
                { key: 'reference', translationKey: 'id', size: 'm' },
                { key: 'createdAt', translationKey: 'orderDate', type: 'date' },
                { key: 'customer.email', type: 'userEmail', size: 'xl' },
                { key: 'channel', translationKey: 'canal' },
                { key: 'total', type: 'formatPrice' },
                { key: 'paymentStatus', type: 'tagType', size: 'm' },
                { key: 'state', translationKey: "orderStatus", type: 'tagType', size: 'm' },
                { key: 'shippingStatus', type: 'tagType', size: 'm' },
                { key: 'returnStatus', type: 'tagType', size: 'm' },
                { key: 'itemsNb', translationKey: 'countArticles' },
                { key: 'shippingAddress.countryCode' }
            ],
            resalesFields: [
                { key: 'id', type: 'eye', route: 'recom_resale', size: 'xs' },
                { key: 'brand.name', type: 'client', size: 'l', noSortable: true },
                { key: 'reference', translationKey: 'id', size: 'm' },
                { key: 'customer.email', type: 'userEmail', size: 'xl' },
                { key: 'itemsProposedAmount', translationKey: "credit_price", type: 'formatPrice' },
                { key: 'state', type: 'tagType', size: 'm' },
                { key: 'itemsCreditedAmount', translationKey: "creditGranted", type: 'formatPrice' },
                { key: 'itemsNb', translationKey: 'countArticles' },
                { key: 'channel', translationKey: 'canal' },
                { key: 'shippingAddress.countryCode' },
            ],
            creditsFields: [
                { key: 'createdAt', translationKey: 'recom_transactionsDate', type: 'date' },
                { key: 'amount', translationKey: 'recom_transactionsAmount', type: 'formatPrice' },
                { key: 'category', translationKey: 'recom_transactionsType', type: 'tagType' },
                { key: 'description', translationKey: 'recom_transactionsDescription', size: 'xxl' },
            ]
        }
    },
    computed: {
        ...mapState(['customer', 'customerLoading'])
    },
    methods: {
        ...mapActions(['getRecomCustomer', 'exportTable']),
        getOrders() {
            API.get(`${this.$listRoutes.recomCustomerOrders}`.replace(':id', this.customer.id))
                .then(res => this.orders = res.data)
        },
        getResales() {
            API.get(`${this.$listRoutes.recomCustomerTradeins}`.replace(':id', this.customer.id))
                .then(res => this.resales = res.data)
        },
        getCredits() {
            API.get(`${this.$listRoutes.recomCustomerTransactions}`.replace(':id', this.customer.id))
                .then(res => this.credits = res.data)
        }
    },
    watch: {
        customer() {
            if (this.customer) {
                this.getOrders();
                this.getResales();
                this.getCredits();
            }
        }
    },
    mounted() {
        if (this.customer) {
            this.getOrders();
            this.getResales();
            this.getCredits();
        }
    }
}
</script>