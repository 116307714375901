<template>
    <TemplateTable :title="$t('menu.recom-vouchers')" :text="$t('recomVouchers.description')" name="recomVouchers"
        :url="$listRoutes.recomVouchers" :table="recomVouchers" :filters="recomVouchersFilters" :fields="fields" />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'RecomVouchersPage',
    components: { TemplateTable },
    data() {
        return {
            fields: [
                { key: 'id', type: 'eye', route: 'recom-voucher', size: 'xs' },
                { key: 'id', type: 'copy', size: 'xl' },
                { key: 'brand', translationKey: 'client', type: 'client', size: 'l' },
                { key: 'customer', translationKey: 'user', type: 'userEmail', size: 'xl' },
                { key: 'amount', type: 'formatPrice' },
                { key: 'code', type: 'editVoucher', size: 'l' },
                { key: 'createdAt', type: 'date', size: 'm' },
                { key: 'expiredAt', translationKey: 'expiresOn', type: 'date', size: 'm' },
                { key: 'type', type: 'voucherType', size: 'm', translationKey: 'channel_of_use' },
                { key: 'state', param: 'recom_voucherState', type: 'tagType', size: 'm' },
                { key: 'burned', translationKey: 'isBurned', type: 'boolean', size: 'm' },
                { key: 'burnedAt', translationKey: 'burnedAt2', type: 'date', size: 'm' },
                { key: 'customer.countryCode', translationKey: 'country', size: 's' },
            ]
        }
    },
    computed: {
        ...mapState(['recomVouchers', 'recomVouchersFilters'])
    }
}
</script>