<template>
    <section>
        <PageHeader :title="$t('menu.configuration-websites')"/>
        <PageLoader v-if="!clients"/>
        <Checkerboard v-else>
            <CheckerboardItem v-for="client in allowedClients" :key="client.id">
                <TextTitle size="s">{{ client.name }} ({{  client.id }})</TextTitle>
                <LinkUnderline :to="client.frontUrl">{{ client?.frontUrl.replace('https://', '').replace('www', '') }}</LinkUnderline>
                <ButtonLink size="s" icon="right" color="grey" :to="{name: 'client', params: { id: client.id }}">{{ $t('global.see') }}</ButtonLink>
            </CheckerboardItem>
        </Checkerboard>
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import PageLoader from '@/components/ui/page/PageLoader';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem';
import LinkUnderline from '@/components/ui/link/LinkUnderline';
import TextTitle from '@/components/ui/text/TextTitle';
import ButtonLink from '@/components/ui/button/ButtonLink';
import Checkerboard from '@/components/ui/checkerboard/Checkerboard';

export default {
    name: 'ClientsPage',
    components: {Checkerboard, ButtonLink, TextTitle, LinkUnderline, CheckerboardItem, PageLoader, PageHeader},
    computed: {
        ...mapState(['clients']),
        ...mapGetters(['userClients']),
        allowedClients() {
            if (this.userClients?.length) return this.clients.filter(client => this.userClients.find(x => x.id === client.id));
            return this.clients
        }
    },
    beforeMount() {
        if (this.userClients?.length === 1) this.$router.push({name: 'client', params: {id: this.userClients[0].id}})
    }
}
</script>