<template>
    <TemplateTable
            :title="$t('menu.recom_resales-all')"
            :text="$t('recom_resales.description')"
            name="resales"
            :url="$listRoutes.recomResales"
            :table="resales"
            :filters="resalesFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'RecomResalesPage',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'recom_resale', size: 'xs'},
                {key: 'brand.name', type: 'client', translationKey: 'brand', size: 'l', noSortable: true},
                {key: 'validatedAt', translationKey: 'tradeinCreateAt', type: 'date', size: 'm'},
                {key: 'reference', translationKey: 'id', size: 'm'},
                {key: 'customer.email', type: 'userEmail', size: 'xl'},
                {key: 'state', type: 'cmsTradeInState', size: 'm'},
                {key: 'itemsProposedAmount', translationKey: "purposed_price", type: 'formatPrice'},
                {key: 'itemsCreditedAmount', translationKey:"creditGranted", type: 'formatPrice'},
                {key: 'itemsNb', translationKey: 'countArticles'},
                {key: 'channel', translationKey: 'canal', type: 'tagType'},
                {key: 'shippingAddress.countryCode', translationKey: 'country'},
                {key: 'customer.profile', type: 'tagType', size: 'm'},
            ]
        }
    },
    computed: {
        ...mapState(['resales', 'resalesFilters'])
    }
}
</script>