import Customers from '@/components/pages/admin/customers/customer/Customers';
import RecomCustomers from '@/components/pages/admin/customers/customer/RecomCustomers';
import RecomSubscribers from '@/components/pages/admin/customers/customer/RecomSubscribers';
import Wallets from '@/components/pages/admin/customers/wallet/Wallets';
import Customer from '@/components/pages/admin/customers/customer/Customer';
import RecomCustomer from '@/components/pages/admin/customers/customer/RecomCustomer';
import Wallet from '@/components/pages/admin/customers/wallet/Wallet';
import WalletAdd from '@/components/pages/admin/customers/wallet/WalletAdd';

const customersRoutes = [
    {
        path: '/admin/utilisateurs',
        name: 'customers',
        redirect: {
            name: 'customers-all'
        },
        meta: {
            title: 'users',
            icon: 'user',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/utilisateurs/tous-les-utilisateurs',
        name: 'customers-all',
        component: Customers,
        meta: {
            title: 'users-all',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/utilisateurs-faume/tous-les-utilisateurs',
        name: 'recom_customers-all',
        component: RecomCustomers,
        meta: {
            title: 'recom_customers-all',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/utilisateurs-faume/abonnes',
        name: 'recom_subscribers',
        component: RecomSubscribers,
        meta: {
            title: 'recom_subscribers',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/utilisateurs/portefeuilles',
        name: 'customers-wallets',
        component: Wallets,
        meta: {
            title: 'wallets',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_SAV', 'ROLE_BRAND_FINANCE'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/utilisateurs/portefeuilles/:id',
        name: 'wallet',
        component: Wallet,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/utilisateurs/:id',
        name: 'customer',
        component: Customer,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/utilisateurs-faume/:id',
        name: 'recom_customer',
        component: RecomCustomer,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/utilisateurs/portefeuilles/:id/ajouter',
        name: 'wallet-add',
        component: WalletAdd,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/utilisateurs/*',
        redirect: {
            name: 'customers'
        }
    }
];

export default customersRoutes;