<template>
    <TemplateDetails :data="store" :loading="storeLoading" :get-function="getStore">

        <PageBack/>

        <PageHeader v-if="store" :title="store.name">
            <template v-slot:actions>
                <ButtonLink v-if="store.salesPoint" size="m" color="grey" icon="tshirt" :to="{name: 'client-store-transfers'}">{{ $t('client.stock.see_transfers') }}</ButtonLink>
                <ButtonLink v-if="resaleUrl" size="m" color="grey" icon="shopify" :to="resaleUrl" :open-in-same-window="true">{{ $t('store.resale.btn') }}</ButtonLink>
                <ButtonLink size="m" color="black" icon="edit" :to="{name: 'client-store-edit', params: {id: id, storeId: store.id}}">{{ $t('store.edit_btn') }}</ButtonLink>
            </template>
            <template v-slot:text v-if="store.isTest">
                <TagItem color="green">{{ $t('global.test') }}</TagItem>
            </template>
        </PageHeader>

        <GridContainer v-if="store">
            <GridCard>
                <GridContent>
                    <SectionTableInfos :fields="fields" :object="store"/>
                </GridContent>
            </GridCard>

            <GridCard v-if="fieldsAddress?.find(field => store[field.key])">
                <GridContent>
                    <GridHeader :title="$t('global.address')"/>
                    <SectionTableInfos :fields="fieldsAddress" :object="store"/>
                </GridContent>
            </GridCard>

            <template v-slot:aside>
                <GridCard v-if="schedule">
                    <GridContent>
                        <GridHeader :title="$t('global.schedule')"/>
                        <SectionTableInfos :fields="Object.keys(schedule).map(key => ({key: key, translation: `days.${key}`}))" :object="schedule"/>
                    </GridContent>
                </GridCard>

                <GridCard v-if="bookingSchedule">
                    <GridContent>
                        <GridHeader :title="$t('global.bookingSchedule')"/>
                        <SectionTableInfos :fields="Object.keys(bookingSchedule).map(key => ({key: key, translation: `days.${key}`}))" :object="bookingSchedule"/>
                    </GridContent>
                </GridCard>
            </template>

        </GridContainer>

    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import { mapState, mapActions, mapGetters } from 'vuex';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import ButtonLink from '@/components/ui/button/ButtonLink';
import TagItem from '@/components/ui/tag/Tag';

export default {
    name: 'StorePage',
    components: {TagItem, ButtonLink, GridHeader, GridContent, GridCard, GridContainer, SectionTableInfos, PageHeader, PageBack, TemplateDetails},
    data() {
        return {
            fields: [
                {key: 'client', type: 'client'},
                {key: 'name'},
                {key: 'resalesPoint', type: 'boolean'},
                {key: 'salesPoint', type: 'boolean'},
                {key: 'id', type: 'copy'},
                {key: 'shopifyId', type: 'copy'},
                {key: 'token', type: 'copy'},
                {key: 'tpeToken', type: 'copy'},
                {key: 'email', type: 'copy'},
                {key: 'phone'},
                {key: 'createdAt', type: 'date'},
                {key: 'updatedAt', type: 'date'},
            ],
            fieldsAddress: [
                {key: 'city'},
                {key: 'zip'},
                {key: 'street'},
                {key: 'countryCode'},
                {key: 'long'},
                {key: 'lat'},
            ]
        }
    },
    computed: {
        ...mapState(['clients', 'store', 'storeLoading', 'clientsMarkets']),
        ...mapGetters(['getClientMarketByCode']),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        schedule() {
            return this.store?.schedule ? JSON.parse(this.store.schedule) : null
        },
        bookingSchedule() {
            return this.store?.bookingSchedule ? JSON.parse(this.store.bookingSchedule) : null
        },
        resaleUrl() {
            return this.client && this.store?.token ? `${this.client.frontUrl}/${this.locale}pages/module-reprise#/boutique?shop=${this.store.token}` : null
        },
        market() {
            return this.getClientMarketByCode(this.id, this.store?.countryCode)
        },
        locale() {
            // find the shopify default locale to handle prefix_except_default strategy for url
            const market = this.clientsMarkets[this.id]?.find(market => market.isDefault === true)
            const shopifyDefaultLocale = market?.defaultLocale?.code?.split('_')[0];
            const lang = this.market?.defaultLocale.code.split('_')[0];
            if (!lang) return '';
            return lang === shopifyDefaultLocale ? '' : `${lang}/`
        }
    },
    methods: {
        ...mapActions(['getStore', 'getClientMarkets'])
    },
    beforeMount() {
        this.getClientMarkets()
    }
}
</script>
