<template>
    <TemplateGoldenmetrics
            :title="$t('menu.orders')"
            :categories="categories"
            :route="$listRoutes.dashboardOrders"
    />
</template>

<script>
import TemplateGoldenmetrics from '@/components/templates/TemplateGoldenmetrics';

export default {
    name: 'DashboardOrders',
    components: {TemplateGoldenmetrics},
    data() {
        return {
            categories: [
                { name: 'visitNumber', icon: 'users' },
                { name: 'grossSale', format: 'money', icon: 'bag' },
                { name: 'netSale', format: 'money', icon: 'bag-empty' },
                { name: 'avgConversionRate', format: '%', icon: 'percent-border' },
                { name: 'returnValue', format: 'money', icon: 'arrow-return-money' },
                { name: 'returnNumber', icon: 'arrow-return-hashtag' },
                { name: 'returnRate', format: '%', icon: 'arrow-return-percent' },
                { name: 'orderNumber', icon: 'box' },
                { name: 'averageArticle', icon: 'tshirt-box' },
                { name: 'averageCartGross', format: 'money', icon: 'calendar-money' },
                { name: 'averageCartReturn', format: 'money', icon: 'calendar-money' },
                { name: 'soldArticleNumber', icon: 'hashtag-border' },
                { name: 'soldArticleNumberNet', icon: 'hashtag-border' },
                { name: 'averageArticlePrice', format: 'money', icon: 'money' },
                { name: 'averageArticlePriceNet', format: 'money', icon: 'money' },
                { name: 'str', format: '%', icon: 'box' },
                { name: 'stock', icon: 'tshirt-click' }
            ]
        }
    }
}
</script>