<template>
    <TemplateTable
            :title="$t('menu.recom_articles')"
            :text="$t('recom_articles.description')"
            name="recomSoldArticles"
            :url="$listRoutes.recomSoldArticles"
            :table="newRecomSoldArticles"
            :filters="recomSoldArticlesFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';

export default {
    name: 'RecomSoldArticles',
    components: {TemplateTable},
    data() {
        return {
            loading: true,
            fields: [
                {key: 'order.brand.name', type: 'client', translationKey: 'client', size: 'm' },
                {key: 'tradeIn.reference', translationKey: "articleId", size: 'l'},
                {key: 'productName', size: 'xxl'},
                {key: 'tradeIn.ean', size: 'l'},
                {key: 'tradeIn.condition', type: 'tagType', size: 'm'},
                {key: 'state', type: 'tagType', size: 'm'},
                {key: 'order.createdAt', translationKey: 'orderDate', type: 'date', size: 'm'}, 
                {key: 'order.reference', translationKey: "idOrder", size: 'l'},
                {key: 'order.customer.email', type: 'copy', size: 'l', translationKey: 'buyerEmail'},
                {key: 'tradeIn.controlReview.price', translationKey:"tradeInPrice", type: 'formatPrice'},
                {key: 'unitPriceWithoutTax', translationKey:"htSellPrice", type: 'formatPrice'},
                {key: 'unitPriceTax', type: 'formatPrice'},
                {key: 'unitPrice', translationKey:"ttcSellPrice", type: 'formatPrice'},
                {key: 'order.customer.profile', type: 'tagType', translationKey: 'buyerProfil', size: 'm'},
                {key: 'order.channel', translationKey:"canalVente", type: 'tagType', size: 'm'},
                {key: 'order.shippingAddress.countryCode', size: 'm'}
            ]
        }
    },
    computed: {
        ...mapState(['recomSoldArticles', 'recomSoldArticlesFilters']),
        newRecomSoldArticles(){
            if(this.recomSoldArticles?.items.length) {
                return { 
                    ...this.recomSoldArticles,
                    items: this.recomSoldArticles.items.map(item => {
                        return {
                            ...item,
                            sellingDate: item.order.payments?.[0]?.createdAt,
                        }
                    })
                }
            } else {
                return {
                    items:[]
                }
            }
        }
    },
    watch: {
        newRecomSoldArticles(){
            this.loading = false;
        }
    },
}
</script>