<template>
    <div class="dashboard-performances">
        <PageHeader :title="title" :subtitle="subtitle"/>

        <FieldSelect v-if="clientsOptions?.length && clientIsRequired"
                     :key="client"
                     :selected="client"
                     :options="clientsOptions"
                     :label="$t('global.client')"
                     @input="e => client = e"/>

        <FieldSearchSelect v-else-if="clientsOptions?.length"
                           :model="selectedClients"
                           :options="clientsOptions"
                           :label="$t('global.clients')"
                           @change="e => selectedClients = e"/>

        <PageFilters>

            <template v-slot:left>
                <FieldSearchSelect v-if="clientCountries?.length"
                                   :model="selectedCountries"
                                   :options="clientCountries"
                                   :label="$t('global.country')"
                                   @change="e => selectedCountries = e"/>
                <FieldPeriod
                        :start="period1Start"
                        :end="period1End"
                        :label="$t('goldenmetrics.period1')"
                        :disabled-today="true"
                        @change="e => {period1Start = e.start; period1End = e.end; updatePeriod2(e.comparaison); getData();}"/>

                <FieldPeriod
                        v-if="comparaison"
                        :start="period2Start"
                        :end="period2End"
                        :label="$t('goldenmetrics.compare')"
                        :disabled-today="true"
                        :hide-suggestion="true"
                        :text="comparaisonType === 'period' ? null : $t('global.none')"
                        @change="e => {period12Start = e.start; period2End = e.end; getData();}"
                >
                    <li :class="{'is-active': comparaisonType === 'period'}" @click.prevent="comparaisonType = 'period'">{{ $t('global.custom') }}</li>
                    <li :class="{'is-active': comparaisonType === 'none'}" @click.prevent="comparaisonType = 'none'">{{ $t('global.none') }}</li>
                </FieldPeriod>

            </template>

            <template v-slot:right v-if="dashboardFilters">
                <FiltersButton @click.native.prevent="openFilters = true"/>
            </template>
        </PageFilters>

        <PageLoader v-if="loading"/>

        <CheckerboardContainer v-else-if="dashboardData">
            <CheckerboardItem
                    v-for="(item, key, i) in dashboardData"
                    :title="(item.unit === '€' && item.value ? item.value / 100 : item.value) | goldenMetrics(item.unit)"
                    :subtitle="$t(`dashboard.${key}.title`)"
                    :info="$t(`dashboard.${key}.description`)"
                    :image="dashboardIcons[key] ? `/img/goldenmetrics/${dashboardIcons[key]}.svg` : null"
                    :statistic="comparaisonType === 'period' ? item.comparePercent : null"
                    :key="i"/>
        </CheckerboardContainer>

        <FiltersPopin ref="filtersPopin"
                      :visible="openFilters"
                      :filters="dashboardFilters"
                      :no-translate="true"
                      @close="openFilters = false"/>

    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import PageFilters from '@/components/ui/page/PageFilters';
import moment from 'moment';
import FieldPeriod from '@/components/ui/form/fields/FieldPeriod';
import FieldSearchSelect from '@/components/ui/form/fields/FieldSearchSelect';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import CheckerboardContainer from '@/components/ui/checkerboard/Checkerboard';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem';
import PageLoader from '@/components/ui/page/PageLoader';
import FiltersButton from '@/components/ui/filters/FiltersButton';
import FiltersPopin from '@/components/ui/filters/FiltersPopin';

export default {
    name: 'TemplateDashboard',
    components: {FiltersPopin, FiltersButton, PageLoader, CheckerboardItem, CheckerboardContainer, FieldSelect, FieldSearchSelect, FieldPeriod, PageFilters, PageHeader},
    props: {
        title: String,
        clientIsRequired: Boolean,
        route: String,
        filtersRoute: String,
        comparaison: Boolean,
    },
    data() {
        return {
            openFilters: false,
            client: null,
            selectedCountries: [],
            period1Start: moment().subtract(7, 'd').format('YYYY-MM-DD'),
            period1End: moment().subtract(1, 'd').endOf('isoWeek').format('YYYY-MM-DD'),
            period2Start:  moment().subtract(14, 'd').startOf('isoWeek').format('YYYY-MM-DD'),
            period2End: moment().subtract(14, 'd').endOf('isoWeek').format('YYYY-MM-DD'),
            selectedTags: null,
            loading: false,
            selectedClients: [],
            comparaisonType: 'period'
        }
    },
    methods: {
        ...mapActions(['getDashboardData', 'getDashboardFilters', 'getClients']),
        getData() {
            if (!this.clientIsRequired || this.client) {
                this.loading = true;
                let playload = {
                    start: `${this.period1Start} 00:00:00`,
                    end: `${this.period1End} 23:59:59`,
                    tags: this.selectedTags || [],
                    countries: this.selectedCountries
                }
                if (this.comparaison) {
                    playload.comparisonStart = `${this.period2Start} 00:00:00`;
                    playload.comparisonEnd = `${this.period2End} 23:59:59`;
                }
                if (!this.clientIsRequired) playload.clients = this.selectedClients
                const route = this.apiRoute;
                this.getDashboardData({route, playload})
                        .catch(err => console.error(err))
                        .finally(() => this.loading = false)
            }
        },
        getFilters() {
            this.getDashboardFilters(this.apiFiltersRoute)
                    .catch(err => console.error(err))
        },
        getFiltersFromUrl() {
            let filters = [];
            if (this.$route.query) {
                Object.keys(this.$route.query).forEach(key => {
                    filters.push({
                        key: key.split(':')[0],
                        values: this.$route.query[key]
                    })
                });
            }
            this.selectedTags = [...filters]
        },
        updatePeriod2(comparaison) {
            if (this.comparaison) {
                if (comparaison && comparaison.start && comparaison.end) {
                    this.period2Start = comparaison.start;
                    this.period2End = comparaison.end;
                } else {
                    const start = moment(this.period1Start, 'YYYY-MM-DD');
                    const end = moment(this.period1End, 'YYYY-MM-DD');
                    const days = end.diff(start, 'days') + 1;
                    this.period2Start = start.clone().subtract(days, 'd').format('YYYY-MM-DD');
                    this.period2End = start.clone().subtract(1, 'd').format('YYYY-MM-DD');
                }
            }
        },
    },
    computed: {
        ...mapState(['dashboardData', 'dashboardFilters', 'dashboardIcons', 'clients']),
        ...mapGetters(['userClientsList']),
        clientCountries() {
            if (this.clientIsRequired && !this.client) return [];
            if (!this.userClientsList?.length) return [];
            let clients = this.userClientsList;
            if (this.clientIsRequired) clients = this.clients.filter(client => client.id == this.client);
            if (!this.clientIsRequired && this.selectedClients?.length) clients = clients.filter(client => this.selectedClients.includes(client.id));
            if (this.selectedCountries?.length) {
                clients = clients.filter(client => this.selectedCountries.find(countryCode => client.countries.includes(countryCode)))
            }
            return clients
                    .map(client => client.countries)
                    .reduce((allCountries, countries) => {
                        countries.forEach(country => {
                            if (!allCountries.includes(country)) allCountries.push(country);
                        });
                        return allCountries;
                    }, [])
                    .map(country => ({value: country, name: this.$t(`countries_code.${country}`)}))
        },
        subtitle() {
            return this.client && this.clientIsRequired ? this.clientsOptions?.find(x => x.id == this.client)?.label : null
        },
        clientsOptions() {
            if (this.clientIsRequired) return this.userClientsList?.map(x => ({id: x.id, label: x.name})) || [];
            let clients = this.userClientsList;
            if (this.selectedCountries?.length) clients = clients?.filter(client => this.selectedCountries.find(country_code => client.countries.includes(country_code)))
            return clients?.map(x => ({value: x.id, name: x.name})) || []
        },
        rangePeriod() {
            return {
                start: moment(this.period1Start, 'YYYY-MM-DD').toDate(),
                end: moment(this.period1End, 'YYYY-MM-DD').toDate()
            }
        },
        apiRoute() {
            return this.clientIsRequired ? this.route.replace(':id', this.client) : this.route
        },
        apiFiltersRoute() {
            return this.clientIsRequired ? this.filtersRoute.replace(':id', this.client) : this.filtersRoute
        }
    },
    watch: {
        client() {
            if (this.clientIsRequired) this.selectedCountries = [...this.clientCountries].map(x => x.value);
            this.getFilters();
            this.getData();
        },
        selectedCountries() {
            this.getData();
        },
        selectedClients() {
            this.getData();
        },
        '$route.query': {
            handler() {
                this.getFiltersFromUrl();
                this.getData();
            },
            deep: true,
            immediate: true
        },
    },
    async mounted() {
        if (!this.clients) this.getClients();
        if (this.clientIsRequired) this.client = this.userClientsList?.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)[0].id;
        else {
            this.getFilters();
            this.getData();
        }
    }
}
</script>

<style lang="scss">
.dashboard-performances {
    & > .field-select {
        position: relative;
        z-index: 5;
    }

    .filters__left {
        @media screen and (min-width: 1200px) {
            .popin-calendar__inner {
                left: auto;
                right: 0;
            }
        }
    }

    @media screen and (max-width: 1199px) {
        .filters {
            align-items: flex-start;
        }
    }
}
</style>