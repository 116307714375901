<template>
    <TemplateDetails :get-function="getCustomer" :data="customer" :loading="customerLoading">

        <PageBack/>

        <PageHeader :title="$t('customer.title')" v-if="customer">
            <template v-slot:actions>
                <ButtonLink v-if="customer.walletId" :to="{name: 'wallet', params: {id: customer.walletId}}" color="grey" icon="wallet" size="m">{{ $t('customer.btn_wallet') }}</ButtonLink>
                <ButtonLink v-if="customer.memberAreaUrl" :to="customer.memberAreaUrl" color="blue2" icon="profile" size="m" target="_blank">{{ $t('customer.btn_profile') }}</ButtonLink>
            </template>
        </PageHeader>

        <GridContainer>
            <GridCard>
                <GridContent>
                    <SectionTableInfos :object="customer" :fields="fields"/>
                </GridContent>
            </GridCard>

            <template v-slot:aside>
                <GridCard v-if="customer && (customer.address1 || customer.city1 || customer.phone)" >
                    <GridContent>
                        <SectionTableInfos :object="customer" :fields="fieldsAddress" :title="$t('global.address')"/>
                    </GridContent>
                </GridCard>

                <GridCard v-if="customer && customer.nbOrders">
                    <GridContent>
                        <SectionTableInfos :object="customer" :fields="fieldsOrders" :title="$t('global.orders')"/>
                    </GridContent>
                </GridCard>

                <GridCard v-if="customer && customer.nbReprises">
                    <GridContent>
                        <SectionTableInfos :object="customer" :fields="fieldsResales" :title="$t('global.resales')"/>
                    </GridContent>
                </GridCard>
            </template>

            <template v-slot:bottom>

                <div v-if="orders?.items?.length">
                    <GridHeader :title="$t('menu.orders')" :count="orders.pagination.totalCount">
                        <ButtonLink size="m" color="black" icon="eye" :to="{name: 'orders', query: {'user:eq': customer.email}}">{{ $t('customer.btn_orders') }}</ButtonLink>
                    </GridHeader>
                    <TableSticky :fields="ordersFields" :data="orders.items"/>
                </div>

                <div v-if="resales?.items?.length">
                    <GridHeader :title="$t('menu.resales')" :count="resales.pagination.totalCount">
                        <ButtonLink size="m" color="black" icon="eye" :to="{name: 'resales-all', query: {'user:eq': customer.email, page: 1, limit: 25}}">{{ $t('customer.btn_resales') }}</ButtonLink>
                    </GridHeader>
                    <TableSticky :fields="resalesFields" :data="resales.items"/>
                </div>

                <div v-if="credits?.items?.length">
                    <GridHeader :title="$t('menu.credits')" :count="credits.pagination.totalCount">
                        <ButtonLink size="m" color="black" icon="eye" :to="{name: 'vouchers', query: {'user:eq': customer.email}}">{{ $t('customer.btn_credits') }}</ButtonLink>
                    </GridHeader>
                    <TableSticky :fields="creditsFields" :data="credits.items"/>
                </div>

            </template>

        </GridContainer>

    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import { mapActions, mapState } from 'vuex';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import ButtonLink from '@/components/ui/button/ButtonLink';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import API from '@/api/api-admin';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import GridHeader from '@/components/ui/grid/GridHeader';

export default {
    name: 'CustomerPage',
    components: {
        GridHeader,
        TableSticky,
        GridContent, GridCard, GridContainer, ButtonLink, SectionTableInfos, PageHeader, PageBack, TemplateDetails},
    data() {
        return {
            fields: [
                {key: 'id'},
                {key: 'shopifyId', type: 'shopifyCustomer'},
                {key: 'firstname'},
                {key: 'lastname'},
                {key: 'email', type: 'copy'},
                {key: 'birthday'},
                {key: 'createdAt', type: 'date'},
                {key: 'updatedAt', type: 'date'},
                {key: 'locale', type: 'flag'},
                {key: 'optinEmail', type: 'tagType'},
                {key: 'optinSMS', type: 'boolean'},
                {key: 'client', type: 'client'},
            ],
            fieldsAddress: [
                {key: 'firstname'},
                {key: 'lastname'},
                {key: 'address1'},
                {key: 'address2'},
                {key: 'zip1'},
                {key: 'city1'},
                {key: 'country'},
                {key: 'phone'},
            ],
            fieldsOrders: [
                {key: 'nbOrders'},
                {key: 'lastOrderDate', type: 'date'},
            ],
            fieldsResales: [
                {key: 'nbReprises'},
                {key: 'lastReturnDate', type: 'date'},
            ],
            orders: null,
            resales: null,
            credits: null,
            ordersFields: [
                {key: 'order.id', type: 'eye', route: 'order', size: 'xs'},
                {key: 'orderId', type: 'shopifyOrder', size: 'm'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'orderCreatedAt', type: 'date'},
                {key: 'itemNb'},
                {key: 'amountWithoutTax', type: 'formatPrice'},
                {key: 'amountWithTax', type: 'formatPrice'},
                {key: 'returnItemNb'},
                {key: 'saleType', type: 'tagType'},
                {key: 'paymentStatus', type: 'tagType', size: 'm'},
                {key: 'saleCountry'},
            ],
            resalesFields: [
                {key: 'id', type: 'eye', route: 'resale', size: 'xs'},
                {key: 'client', type: 'client', size: 'l', noSortable: true},
                {key: 'source', type: 'tagType'},
                {key: 'shopifyId', type: 'shopifyProduct', size: 'm'},
                {key: 'createdAt', type: 'date'},
                {key: 'tradeinPrice', type: 'formatPrice'},
                // {key: 'logisticsInformations.carrierSendingDate', type: 'date'},
                // {key: 'logisticsInformations.carrierDeliveryDate', type: 'date'},
                {key: 'logisticsInformations.warehouseReceiveDate', type: 'date'},
                {key: 'logisticsInformations.warehouseOpenDate', type: 'date'},
                {key: 'logisticsInformations.warehouseValidationDate', type: 'date'},
                {key: 'logisticsInformations.warehouseShootingDate', type: 'date'},
                {key: 'shopifyPublishedDate', type: 'date'},
                {key: 'shopifyPublished', type: 'boolean'},
                {key: 'inStock', type: 'boolean'},
                {key: 'logisticianCondition', type: 'tagType', noSortable: true},
                {key: 'irl', type: 'boolean'},
                {key: 'realStore', type: 'realStore', size: 'xxl'},
                {key: 'country', size: 'xs', noSortable: true},
            ],
            creditsFields: [
                {key: 'client', type: 'client', size: 'l'},
                {key: 'createdAt', type: 'date'},
                {key: 'amount', type: 'formatPrice'},
                {key: 'shopifyId', type: 'copy', size: 'm'},
                {key: 'type', type: 'tagType'},
                {key: 'country', size: 'xs'},
            ]
        }
    },
    computed: {
        ...mapState(['customer', 'customerLoading'])
    },
    methods: {
        ...mapActions(['getCustomer', 'exportTable']),
        getOrders() {
            API.get(`${this.$listRoutes.orders}?page=1&limit=10&user[eq]=${this.customer.email}`)
                    .then(res => this.orders = res.data)
        },
        getResales() {
            API.get(`${this.$listRoutes.resales}?page=1&limit=10&user[eq]=${this.customer.email}`)
                    .then(res => this.resales = res.data)
        },
        getCredits() {
            API.get(`${this.$listRoutes.promotionsCredits}?page=1&limit=10&user[eq]=${this.customer.email}`)
                    .then(res => this.credits = res.data)
        }
    },
    watch: {
        customer() {
            if (this.customer) {
                this.getOrders();
                this.getResales();
                this.getCredits();
            }
        }
    },
    mounted() {
        if (this.customer) {
            this.getOrders();
            this.getResales();
            this.getCredits();
        }
    }
}
</script>